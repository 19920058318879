import React , {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Avatar, Button, Container, useMediaQuery, Grid, Alert } from "@mui/material";
import { Stack } from "@mui/system";
import ButtonBar from "../Components/ButtonBar";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import axios from "axios";
import LoadingPage from "../Components/LoadingPage";
import TextFieldComp from "../Components/TextFieldComp";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AddIcon from '@mui/icons-material/Add';
import Success from "../Components/Success";
import RemoveIcon from '@mui/icons-material/Remove';
import ContactIcon from "../Components/ContactIcon";

export default function Profile() {
  const windowHeight = React.useRef(window.innerHeight);
  const matches = useMediaQuery("(min-width:1024px)");
  const { type } = useSelector((state) => state.auth);
  const [viewtxtfield, setViewTxtField] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState("");
  const [workP, setWorkP] = useState([]);
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [city, setCity] = useState("");
  const [cityId, setCityId] = useState("");
  const [birthday, setBirthday] = useState("");
  const [specilization, setSpecilization] = useState([]);
  const [secondNumber, setSecondNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [gender, setGender] = useState("");
  const [rows, setRows] = useState([]);
  const [cities, setCities] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [clinics, setClinics] = useState([]);

  const [openSuccess, setOpenSuccess] = useState(false);

  const handleAddRow = () => {
    setRows([...rows, { id: "", btn: true, workPlace: [] }]);
  };

  const handleDeleteRow = (index) => {
    if (workP.length) {
      setWorkP(workP.filter((_, i) => i !== index));
    }
  };

  const handleWorkPlaceChange = (index, value) => {
    const newRows = rows.map((row, rowIndex) => {
      if (index === rowIndex) {
        return { ...row, id: value };
      }
      return row;
    });
    setRows(newRows);
  };

  const handleButtonClick = (index, isHospital) => {
    const newRows = rows.map((row, rowIndex) => {
      if (index === rowIndex) {
        return {
          ...row,
          btn: isHospital,
          workPlace: isHospital ? hospitals : clinics,
        };
      }
      return row;
    });
    setRows(newRows);
  };



  React.useEffect(() => {
    window.scrollTo(0, 0);
    const token = localStorage.getItem("token");
    const lang = localStorage.getItem("lang");
    let theCity ;
    axios
      .get(`${process.env.REACT_APP_API_URL}get-profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setData(res.data.data.profile);
        setWorkP(res.data.data.profile.workplaces)
        setName(res.data.data.profile.first_name)
        setLastName(res.data.data.profile.last_name)
        setPhone(res.data.data.profile.phone)
        setSecondNumber(res.data.data.profile.second_phone)
        setBirthday(res.data.data.profile.birthdate)
        setLicenseNumber(res.data.data.profile.license_number)
        setCity(res.data.data.profile.location)
        setIdNumber(res.data.data.profile.id_number)
        setGender(res.data.data.profile.gender)
        theCity = res.data.data.profile.location
        axios
        .get(`${process.env.REACT_APP_API_URL}doctor-form-data`, {
          headers: {
            "Accept-Language": lang,
          },
        })
        .then((res) => {
          setCities(res.data.data.cities);
          setSpecializations(res.data.data.specializations);
          setHospitals(res.data.data.hospitals);
          setClinics(res.data.data.clinics);
          setOpen(false);
          const matchedCity = res.data.data.cities.find((c) => c.title === theCity);
          if (matchedCity) {
            setCityId(matchedCity.id);
          }
        });
      });
  }, []);

  const handleSubmit=() =>{
    const token = localStorage.getItem("token");
    const lang = localStorage.getItem("lang");
    if(type === 'doctor'){
      const formData = new FormData();
      formData.append("_method", 'PUT');
      formData.append("first_name", name);
      formData.append("last_name", lastname);
      formData.append("second_phone",  null);
      formData.append("phone", phone);
      if (typeof city === "number") {
        formData.append("location_id", city);
      }
      else{
      formData.append("location_id", cityId)
    }
      formData.append("license_number", licenseNumber);
     if(specilization.length > 0)
     { for(var i = 0 ; i < specilization.length ; i++){
      formData.append("specialization_ids[]", specilization[i]);
        }
      }
      else{
        for(var i = 0 ; i < data.specialization.length ; i++){
          formData.append("specialization_ids[]", data.specialization[i].id);
            }
      }

        for(var i = 0 ; i < rows.length ; i++){
          formData.append("workplace_ids[]", rows[i].workPlace[0].id);
            }
            for(var i = 0 ; i < workP.length ; i++){
              formData.append("workplace_ids[]", workP[i].id);
                }
              
        axios.post(`${process.env.REACT_APP_API_URL}update-profile`,formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": lang,
          },
        }).then(res=>{
          if(res.data.status === true){
          setOpenSuccess(true)
          setTimeout(() => {
            window.location.reload(true)
            }, 2100);
          }
        })

    
      }

      if(type === 'patient'){
        const formData = new FormData();
        formData.append("_method", 'PUT');
        formData.append("first_name", name);
        formData.append("last_name", lastname);
        formData.append("second_phone",  secondNumber);
        formData.append("gender",  gender);
        formData.append("phone", phone);
        formData.append("id_number", idNumber);
        formData.append("birthdate", birthday);
        axios.post(`${process.env.REACT_APP_API_URL}update-profile`,formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": lang,
          },
        }).then(res=>{
          if(res.data.status === true){
          setOpenSuccess(true)
          setTimeout(() => {
            window.location.reload(true)
            }, 2100);
          }
        })

      }



      if(type === 'technician'){
        const formData = new FormData();
        formData.append("_method", 'PUT');
        formData.append("first_name", name);
        formData.append("last_name", lastname);
        formData.append("second_phone",  secondNumber);
        if (typeof city === "number") {
          formData.append("location_id", city);
        }
        else{
        formData.append("location_id", cityId)
      }
        formData.append("phone", phone);
        if(specilization.length > 0)
        { for(var i = 0 ; i < specilization.length ; i++){
         formData.append("specialization_ids[]", specilization[i]);
           }
         }
         else{
           for(var i = 0 ; i < data.specialization.length ; i++){
             formData.append("specialization_ids[]", data.specialization[i].id);
               }
         }

        axios.post(`${process.env.REACT_APP_API_URL}update-profile`,formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": lang,
          },
        }).then(res=>{
          if(res.data.status === true){
          setOpenSuccess(true)
          setTimeout(() => {
            window.location.reload(true)
            }, 2100);
          }
        })
  
      }


      if(type === 'supervisor'){
        const formData = new FormData();
        formData.append("_method", 'PUT');
        formData.append("first_name", name);
        formData.append("last_name", lastname);
        formData.append("second_phone",  null);
        formData.append("phone", phone);

        axios.post(`${process.env.REACT_APP_API_URL}update-profile`,formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": lang,
          },
        }).then(res=>{
          if(res.data.status === true){
          setOpenSuccess(true)
          setTimeout(() => {
            window.location.reload(true)
            }, 2100);
          }
        })
      }


  }

  const placeholder = data.specialization && data.specialization.map(s => s.title).join(", ");

  return (
    <div
      style={{
        backgroundColor: "#F4F4F4",
        minHeight: windowHeight.current,
        paddingBottom: "50px",
      }}
    >
      {open && <LoadingPage open={open} />}

      <ButtonBar />
      {!open && !viewtxtfield && (
        <Container maxWidth="lg" sx={{ mt: "50px" }}>
          <Card
            sx={{
              display: "flex",
              p: "30px",
              minWidth: matches ? "calc(50% - 75px)" : "80%",
              boxShadow: "none",
              position: "relative",
            }}
          >
            <CardContent sx={{ display: "flex", flexDirection: "row" }}>
              <Stack
                direction={matches ? "row" : "column"}
                gap={matches ? 11 : 2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "start",
                }}
              >
                <Avatar />

                <Stack
                  sx={{
                    gap: "20px",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ color: "#008080", fontWeight: "bold" }}
                  >
                    {type === "technician" && t("Technician Details")}
                    {type === "supervisor" && t("Supervisor Details")}
                    {type === "patient" && t("Patient Details")}
                    {type === "doctor" && t("Doctor Details")}
                  </Typography>
                  <Stack
                    direction="row"
                    gap={1}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: matches ? "center" : "",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ color: "#464646", fontSize: "15px" }}>
                      {t("User Name :")} {data.first_name} {data.last_name}
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    gap={1}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: matches ? "center" : "",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ color: "#464646", fontSize: "15px" }}>
                      {t("Phone Number :")} {data.phone}
                    </Typography>
                  </Stack>

                  {type === "patient" || type === "technician" ? (
                    <Stack
                      direction="row"
                      gap={1}
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: matches ? "center" : "",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ color: "#464646", fontSize: "15px" }}>
                        {t("Second Phone Number :")} {data.second_phone}
                      </Typography>
                    </Stack>
                  ) : null}

                  {type === "patient" && (
                    <Stack
                      direction="row"
                      gap={1}
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: matches ? "center" : "",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ color: "#464646", fontSize: "15px" }}>
                        {t("Gender :")} {data.gender}
                      </Typography>
                    </Stack>
                  )}
                </Stack>

                <Stack
                  sx={{
                    gap: "20px",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  {type === "patient" && (
                    <Stack
                      direction="row"
                      gap={1}
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: matches ? "center" : "",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ color: "#464646", fontSize: "15px" }}>
                        {t("Birthday :")} {data.birthdate}
                      </Typography>
                    </Stack>
                  )}

                  <Stack
                    sx={{
                      gap: "20px",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "start",
                    }}
                  >
                    <Stack
                      direction="row"
                      gap={1}
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: matches ? "center" : "",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ color: "#464646", fontSize: "15px" }}>
                        {t("Email :")} {data.email}
                      </Typography>
                    </Stack>

                    {type === "doctor" && (
                      <Stack
                        direction="row"
                        gap={1}
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: matches ? "center" : "",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ color: "#464646", fontSize: "15px" }}>
                          {t("License Number :")} {data.license_number}
                        </Typography>
                      </Stack>
                    )}

                    {type === "doctor" || type === "technician" ? (
                      <Stack
                        direction="row"
                        gap={1}
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: matches ? "center" : "",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ color: "#464646", fontSize: "15px" }}>
                          {t("Location :")} {data.location}
                        </Typography>
                      </Stack>
                    ) : null}
                  </Stack>
                </Stack>

                {type === "patient" && (
                  <Stack
                    direction="row"
                    gap={1}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: matches ? "center" : "",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ color: "#464646", fontSize: "15px" }}>
                      {t("ID Number :")} {data.id_number}
                    </Typography>
                  </Stack>
                )}

                  <Stack
                    sx={{
                      gap: "20px",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "start",
                    }}
                  >
                    {type === "doctor" || type === "technician" ?<Stack
                      direction="row"
                      gap={1}
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: matches ? "center" : "",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ color: "#464646", fontSize: "15px" }}>
                        {t("Specializations :")}
                        {data.specialization &&
                          data.specialization.map((s) => (
                            <li style={{ color: "#464646", fontSize: "15px" }}>
                              {" "}
                              {s.title}{" "}
                            </li>
                          ))}
                      </Typography>
                    </Stack> : null }

                   {type === "doctor" && <Stack
                      direction="row"
                      gap={1}
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: matches ? "center" : "",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ color: "#464646", fontSize: "15px" }}>
                        {t("Workplaces :")}
                        {data.workplaces &&
                          data.workplaces.map((s) => (
                            <li style={{ color: "#464646", fontSize: "15px" }}>
                              {" "}
                              {s.title} <strong> {s.type} </strong>
                            </li>
                          ))}
                      </Typography>
                    </Stack>}
                  </Stack>
                
                


                {!viewtxtfield && (
                  <EditIcon
                    sx={{
                      color: "#008080",
                      cursor: "pointer",
                      position: "absolute",
                      right: localStorage.getItem("lang") === "en" ? "5%" : "",
                      left: localStorage.getItem("lang") === "ar" ? "5%" : "",
                      top: matches ? "" : "90%",
                    }}
                    onClick={() => {
                      setViewTxtField(true);
                    }}
                  />
                )}
              </Stack>
            </CardContent>
          </Card>

        </Container>
      )}

      {viewtxtfield && (
        <Container maxWidth="lg" sx={{ mt: "50px" , display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        position: "relative",bgcolor:'white'}}>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextFieldComp
                  label={"First Name"}
                  onChange={setName}
                  place={name}
                  value={name}
                  h={"50px"}
                  width={"80%"}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextFieldComp
                  label={"Last Name"}
                  onChange={setLastName}
                  place={lastname}
                  value={lastname}
                  h={"50px"}
                  width={"80%"}
                />
              </Grid>
            </Grid>

             {type === "doctor" && 
            <Grid container spacing={1}>
             <Grid item xs={12} sm={6}>
                <TextFieldComp
                  label={"License Number"}
                  onChange={setLicenseNumber}
                  value={licenseNumber}
                  place={licenseNumber}
                  h={"50px"}
                  width={"80%"}
                  type={"number"}
                />
              </Grid>

              <Grid item xs={12} sm={6} sx={{ mt: "10px" }}>
                <label
                  style={{
                    color: "#3D3D3D",
                    fontSize: "15px",
                  }}
                >
                  {t("Phone Number")}
                </label>
                <PhoneInput
                  country={"jo"}
                  enableSearch={true}
                  value={phone}
                  placeholder={phone}
                  onChange={setPhone}
                  style={{ marginTop: "5px" }}
                  inputStyle={{
                    backgroundColor: "transparent",
                    width: "80%",
                    color: "black",
                    fontSize: "13px",
                    border: "2px solid #64C6C6",
                    borderRadius: "10px",
                    height: "50px",
                    outline: "none",
                    ":focus": {
                      border: "2px solid #64C6C6",
                    },
                  }}
                />
              </Grid>
              </Grid>}

              <Grid container spacing={1}>
             {type !== 'doctor' && 
             <Grid item xs={12} sm={6} sx={{ mt: "10px" }}>
                <label
                  style={{
                    color: "#3D3D3D",
                    fontSize: "15px",
                  }}
                >
                  {t("Phone Number")}
                </label>
                <PhoneInput
                  country={"jo"}
                  enableSearch={true}
                  value={phone}
                  placeholder={phone}
                  onChange={setPhone}
                  style={{ marginTop: "5px" }}
                  inputStyle={{
                    backgroundColor: "transparent",
                    width: "80%",
                    color: "black",
                    fontSize: "13px",
                    border: "2px solid #64C6C6",
                    borderRadius: "10px",
                    height: "50px",
                    outline: "none",
                    ":focus": {
                      border: "2px solid #64C6C6",
                    },
                  }}
                />
              </Grid>}

            { type === "patient" || type === "technician" ? <Grid item xs={12} sm={6}>
            <label
                  style={{
                    color: "#3D3D3D",
                    fontSize: "15px",
                  }}
                >
                  {t("Second Phone Number")}
                </label>
                <PhoneInput
                  country={"jo"}
                  placeholder={secondNumber}
                  enableSearch={true}
                  value={secondNumber}
                  onChange={setSecondNumber}
                  style={{ marginTop: "5px" }}
                  inputStyle={{
                    backgroundColor: "transparent",
                    width: "80%",
                    color: "black",
                    fontSize: "13px",
                    border: "2px solid #64C6C6",
                    borderRadius: "10px",
                    height: "50px",
                    outline: "none",
                    ":focus": {
                      border: "2px solid #64C6C6",
                    },
                  }}
                />
              </Grid>   : null }
            </Grid>
            

                <Grid container spacing={1}>
              {type === "doctor" || type === "technician" ? <Grid item xs={12} sm={6}>
              <TextFieldComp
              label="Location"
              onChange={setCity}
              value={city}
              place={city}  
              h="50px"
              width="80%"
              data={cities}
            />
              </Grid>  : null}
              </Grid>


            {type === "doctor"  || type === "technician" ?
             <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <TextFieldComp
                  label="Specializations"
                  onChange={setSpecilization}
                  value={specilization}
                  place={placeholder}
                  h="50px"
                  width={matches ? "90%" : "80%"}
                  dataObj={specializations}
                />
              </Grid>
            </Grid> : null}

            {type === "doctor" && <Grid container spacing={1}>
              {workP.length > 0 && workP.map((row, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={6}>
                    <label style={{ color: "#3D3D3D", fontSize: "15px" }}>
                      {t("Workplace Type")}
                    </label>
                    <Stack
                      direction="row"
                      sx={{
                        border: "1px solid",
                        borderColor: "#64C6C6",
                        borderRadius: "20px",
                        width: matches ? "80%" : "80%",
                        mt: "10px",
                      }}
                    >
                      <Button
                        sx={{
                          borderRadius: "20px",
                          width: "100%",
                          backgroundImage: row.type === "Hospital"
                            ? "linear-gradient(to right, #64C6C6 , #008080)"
                            : "",
                          color: row.btn ? "white" : "black",
                          height: "50px",
                        }}
                       disabled
                      >
                        {t("Hospital")}
                      </Button>

                      <Button
                        sx={{
                          borderRadius: "20px",
                          width: "100%",
                          backgroundImage: row.type === 'Clinic' 
                            ? "linear-gradient(to left, #64C6C6 , #008080)"
                            : "",
                          color: !row.btn ? "white" : "black",
                        }}
                        disabled
                        >
                        {t("Clinic")}
                      </Button>
                    </Stack>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <TextFieldComp
                      label={"Current Workplace"}
                      h={"50px"}
                      width={matches ? "340px" : "280px"}
                      place={row.title}
                      dis={true}
                    />
                    {index === 0 && 
                    <AddIcon
                    sx={{
                      color: "#64C6C6",
                      fontSize: "30px",
                      mt: "30px",
                      cursor: "pointer",
                    }}
                    onClick={handleAddRow}
                  />}
                      <RemoveIcon
                      sx={{
                        color: "#64C6C6",
                        fontSize: "30px",
                        mt: "30px",
                        cursor: "pointer",
                      }}
                      onClick={()=>{handleDeleteRow(index)}}
                    />
                    
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>}




           {type === "doctor" && <Grid container spacing={1}>
              {rows.map((row, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={6}>
                    <label style={{ color: "#3D3D3D", fontSize: "15px" }}>
                      {t("Workplace Type")}
                    </label>
                    <Stack
                      direction="row"
                      sx={{
                        border: "1px solid",
                        borderColor: "#64C6C6",
                        borderRadius: "20px",
                        width: matches ? "80%" : "80%",
                        mt: "10px",
                      }}
                    >
                      <Button
                        sx={{
                          borderRadius: "20px",
                          width: "100%",
                          "&:focus": {
                            color: "white",
                            backgroundImage:
                              "linear-gradient(to right, #64C6C6 , #008080)",
                          },
                          "&:active": {
                            color: "white",
                            backgroundImage:
                              "linear-gradient(to right, #64C6C6 , #008080)",
                          },
                          backgroundImage: row.btn
                            ? "linear-gradient(to right, #64C6C6 , #008080)"
                            : "",
                          color: row.btn ? "white" : "black",
                          height: "50px",
                        }}
                        onClick={() => handleButtonClick(index, true)}
                      >
                        {t("Hospital")}
                      </Button>

                      <Button
                        sx={{
                          borderRadius: "20px",
                          width: "100%",
                          "&:focus": {
                            color: "white",
                            backgroundImage:
                              "linear-gradient(to left, #64C6C6 , #008080)",
                          },
                          backgroundImage: !row.btn
                            ? "linear-gradient(to left, #64C6C6 , #008080)"
                            : "",
                          color: !row.btn ? "white" : "black",
                        }}
                        onClick={() => handleButtonClick(index, false)}
                      >
                        {t("Clinic")}
                      </Button>
                    </Stack>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <TextFieldComp
                      label={"Current Workplace"}
                      onChange={(value) => {
                        handleWorkPlaceChange(index, value);
                      }}
                      value={row.id}
                      h={"50px"}
                      width={matches ? "340px" : "280px"}
                      data={row.workPlace}
                    />
                         {index === 0 &&  workP.length === 0 &&
                    <AddIcon
                    sx={{
                      color: "#64C6C6",
                      fontSize: "30px",
                      mt: "30px",
                      cursor: "pointer",
                    }}
                    onClick={handleAddRow}
                  />}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>}

            


            {type === "patient"  ?               <Grid container spacing={1}>
<Grid item xs={12} sm={6}><TextFieldComp
                  label={"ID Number"}
                  onChange={setIdNumber}
                  value={idNumber}
                  place={idNumber}
                  h={"50px"}
                  width={"80%"}
                /></Grid>
                <Grid item xs={12} sm={6}>
                <TextFieldComp
                  label={"Birthday"}
                  onChange={setBirthday}
                  value={birthday}
                  h={"50px"}
                  place={birthday}
                  width={"80%"}
                  type={'date'}
                />
              </Grid> 
                </Grid>:null}


            <Stack>
              <Button
                sx={{
                  backgroundImage:
                    "linear-gradient(to right, #64C6C6 , #008080)",
                  color: "white",
                  borderRadius: "10px",
                  textTransform: "none",
                  fontSize: "15px",
                  p: "5px",
                  width: "250px",
                  mt: "0px",
                  mb: "50px",
                }}
                onClick={handleSubmit}
              >
                {t('Submit')}
              </Button>
            </Stack>
        </Container>
      )}

    <Success
        open={openSuccess}
        setOpen={setOpenSuccess}
        msg={"Your profile has been successfully updated."}
      />
      <ContactIcon />
    </div>
  );
}
