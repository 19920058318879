import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import TableField from "./TableField";
import { Box, Stack, useMediaQuery } from "@mui/material";
import FieldDisplay from "./FieldDisplay";

export default function SecondTable({
  root,
  setRoot,
  ascending,
  setAscending,
  arch,
  setArch,
  IVC,
  setIVC,
  EDd,
  setEDd,
  LVPWD,
  setLVPWD,
  EDs,
  setEDs,
  LVPWS,
  setLVPWS,
  IVSDd,
  setIVSDd,
  EWave,
  setEWave,
  IVSSd,
  setIVSSd,
  AWave,
  setAWave,
  EF,
  setEF,
  EA,
  setEA,
  LA,
  setLA,
  lavolume,
  setLaVolume,
  volumeIndex,
  setVolumeIndex,
  setPSLAV,
  PSLAV,
  ED,
  setED,
  ED2,
  setED2,
  ED3,
  setED3,
  show,
}) {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:800px)");

  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", width: "100%", direction: "ltr" }}
    >
      <Table
        sx={{
          ".MuiTableCell-root": {
            fontFamily: '"Quicksand", sans-serif',
            border: "1.5px solid #008080",
          },
        }}
      >
        <TableHead sx={{ bgcolor: "#008080" }}>
          <TableRow>
            <TableCell colSpan={4} sx={{ color: "white", fontSize: "15px" }}>
              {t("Cardiac Dimension and Function")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              display: matches ? "" : "flex",
              flexDirection: "column",
              border: matches ? "" : "1.5px solid #008080",
              margin: matches ? "" : "8px 0",
            }}
          >
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: matches ? "none" : "1.5px solid #008080",
                borderRight: matches ? "1.5px solid #008080" : 0,
                alignContent: "start",
              }}
            >
              Arota Root
              <Stack>
              <Stack direction="row" gap={2}>
                <FieldDisplay
                  label="Aorta Root"
                  range="(2.0 - 3.7cm)"
                  value={root}
                  setValue={setRoot}
                  show={show}
                  unit="cm"
                  type="number"
                />
                </Stack>

                <Stack direction="row" gap={2}>
                <FieldDisplay
                  label="Ascending Aorta"
                  range="(< 4.0)"
                  value={ascending}
                  setValue={setAscending}
                  show={show}
                  unit="cm"
                  type="number"
                />
                </Stack>

                <Stack direction="row" gap={2}>
                <FieldDisplay
                  label="Aorta Arch"
                  range="(< 3.5)"
                  value={arch}
                  setValue={setArch}
                  show={show}
                  unit="cm"
                  type="number"
                />
                </Stack>

                <Stack direction="row" gap={2}>
                <FieldDisplay
                  label="IVC"
                  value={IVC}
                  setValue={setIVC}
                  show={show}
                  unit="cm"
                  type="number"
                />
                </Stack>
              </Stack>
            </TableCell>

            {/*---------------------------- second column --------------------------------*/}
            <TableCell
              colSpan={2}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "none",
                alignContent: "start",
              }}
            >
              Left Ventricle
              <Stack>
                <Stack direction="row" gap={2}>
                  <FieldDisplay
                    label="EDd"
                    value={EDd}
                    setValue={setEDd}
                    show={show}
                    unit="cm"
                    type="number"
                    range="(3.7- 5.6cm)"
                  />

                  <FieldDisplay
                    label="LVPWD"
                    value={LVPWD}
                    setValue={setLVPWD}
                    show={show}
                    unit="cm"
                    type="number"
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                  <FieldDisplay
                    label="EDs"
                    value={EDs}
                    setValue={setEDs}
                    show={show}
                    unit="cm"
                    type="number"
                    range="(2.7 - 3.4cm)"
                  />

                  <FieldDisplay
                    label="LVPWS"
                    value={LVPWS}
                    setValue={setLVPWS}
                    show={show}
                    unit="cm"
                    type="number"
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                  <FieldDisplay
                    label="IVSDd"
                    value={IVSDd}
                    setValue={setIVSDd}
                    show={show}
                    unit="cm"
                    type="number"
                    range="(0.6 - 1.2cm)"
                  />

                  <FieldDisplay
                    label="E-Wave"
                    value={EWave}
                    setValue={setEWave}
                    show={show}
                    unit="cm/sec"
                    type="number"
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                  <FieldDisplay
                    label="IVSSd"
                    value={IVSSd}
                    setValue={setIVSSd}
                    show={show}
                    unit="cm"
                    type="number"
                    range="(0.8 - 2.0cm)"
                  />

                  <FieldDisplay
                    label="A-Wave"
                    value={AWave}
                    setValue={setAWave}
                    show={show}
                    unit="cm/sec"
                    type="number"
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                  <FieldDisplay
                    label="EF"
                    value={EF}
                    setValue={setEF}
                    show={show}
                    unit="cm"
                    type="number"
                    range="(50 - 70%)"
                  />

                   <FieldDisplay
                    label="E/A"
                    value={EA}
                    setValue={setEA}
                    show={show}
                    unit="cm"
                    type="number"
                  />
                </Stack>
              </Stack>
            </TableCell>
          </TableRow>

          {/*--------------------------------------------- second row first column */}
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: matches ? "1.5px solid #008080" : "0.5px solid #008080",
              },
              display: matches ? "" : "flex",
              flexDirection: "column",
              border: matches ? "" : "1.5px solid #008080",
              margin: matches ? "" : "8px 0",
            }}
          >
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "none",
                borderRight: "1.5px solid #008080",
                alignContent: "start",
              }}
            >
              Left Atrium
              <Stack>
                <Stack direction="row" gap={2}>
                     <FieldDisplay
                    label="LA Area"
                    value={LA}
                    setValue={setLA}
                    show={show}
                    unit="cm"
                    type="number"
                    range='(< 20cm)'
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                  <FieldDisplay
                    label="LA Volume"
                    value={lavolume}
                    setValue={setLaVolume}
                    show={show}
                    unit="ml"
                    type="number"
                    range='(22 - 52ml)'
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                   <FieldDisplay
                    label="Volume Index"
                    value={volumeIndex}
                    setValue={setVolumeIndex}
                    show={show}
                    unit="m2"
                    type="number"
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                     <FieldDisplay
                    label="PSLAV"
                    value={PSLAV}
                    setValue={setPSLAV}
                    show={show}
                    unit="cm"
                    type="number"
                    range='(1.9 - 4.0cm)'
                  />
                </Stack>
              </Stack>
            </TableCell>

            {/*-------------------------------------- second row second column */}

            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "none",
                alignContent: "start",
              }}
            >
              Right Ventricle
              <Stack direction="row" gap={2}>
                <FieldDisplay
                    label="ED Diameter"
                    value={ED}
                    setValue={setED}
                    show={show}
                    unit="cm"
                    type="number"
                    range='(0.5 - 2.6cm)'
                  />
              </Stack>
            </TableCell>

            {/*------------------------------ second row second column */}

            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "none",
                alignContent: "start",
              }}
            >
              Right Atrium
              <Stack>
                <Stack direction="row" gap={2}>
                   <FieldDisplay
                    label="Size"
                    value={ED2}
                    setValue={setED2}
                    show={show}
                    data={[
                      { title: "-" },
                      { title: "Normal" },
                      { title: "Abnormal" },
                      { title: "Mildly Enlarged" },
                      { title: "Moderately Enlarged" },
                      { title: "Severely Enlarged" },
                      { title: "Small" },
                    ]}
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                      <FieldDisplay
                    label="Area"
                    value={ED3}
                    setValue={setED3}
                    show={show}
                    unit="cm"
                    type="number"
                  />
                </Stack>
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
