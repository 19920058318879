import { Container, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import bg from "../Media/Vector.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css/navigation";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ServiceCard from "./ServiceCard";
import { useTranslation } from "react-i18next";

export default function Services({ services, serviceDescription }) {
  const matches = useMediaQuery("(min-width:1024px)");
  const matche = useMediaQuery("(min-width:450px)");
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: "50px", position: "relative" }} id="Our Services">
      <Stack
        direction="row"
        gap={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography sx={{ fontSize: "30px",textAlign:'center' }}>{t("What Are")} </Typography>
        <Typography sx={{ color: "#64c6c2", fontSize: "30px",textAlign:'center' }}>
          {t("Our Services ?")}
        </Typography>
      </Stack>

      <Container maxWidth="sm" sx={{ textAlign: "center" }}>
        <p style={{ color: "#B0B0B0" }}>
        {serviceDescription}
        </p>
      </Container>

      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "600px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container maxWidth="lg">
          <Swiper
            modules={[Autoplay, Navigation]}
            loop
            autoplay={{
              delay: 8000,
              disableOnInteraction: false,
            }}
            slidesPerView={matches ? 3 : 1}
            navigation={{
              prevEl: ".custom-swiper-prev",
              nextEl: ".custom-swiper-next",
            }}
          >
            {services.map((s) => (
              <SwiperSlide
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap:'wrap'
                }}
              >
                <ServiceCard s={s} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>

        <div className="custom-swiper-prev">
          <ArrowBackIosNewIcon
            style={{
              color: "#64c6c2",
              fontSize: matche?"40px":'25px',
              position: "absolute",
              top: "420px",
              left: matche?"10px":'0px',
              zIndex: "1",
              cursor: "pointer",
            }}
          />
        </div>
        <div className="custom-swiper-next">
          <ArrowForwardIosIcon
            style={{
              color: "#64c6c2",
              fontSize: matche?"40px":'25px',
              position: "absolute",
              top: "420px",
              right: matche?"10px":'0px',
              zIndex: "1",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </div>
  );
}
