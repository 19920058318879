import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { Button, Stack, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function AttachementTable({d30}) {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:800px)");
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate ()
  const handleClick = () => {
    window.open('#/dicom', '_blank');
  };

  return (
    <>
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        width: "100%",
        direction: "ltr",
        border: "1.5px solid #008080",
      }}
    >
      <Table
        sx={{
          ".MuiTableCell-root": {
            fontFamily: '"Quicksand", sans-serif',
            border: "1.5px solid #008080",
          },
        }}
      >
        <TableHead sx={{ bgcolor: "#008080" }}>
          <TableRow>
            <TableCell colSpan={4} sx={{ color: "white", fontSize: "15px" }}>
              {t("Attachements")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              display: matches ? "" : "flex",
              flexDirection: "column",
              border: matches ? "" : "1.5px solid #008080",
              margin: matches ? "" : "8px 0",
            }}
          >
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: matches ? "none" : "1.5px solid #008080",
                borderRight: matches ? "1.5px solid #008080" : 0,
              }}
            >
              <Stack
                direction="row"
                gap={4}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    color: "#464646",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  {t("ECHO Attachements")}
                </p>

                <Button
      sx={{
        backgroundColor: '#008080',
        color: 'white',
        textTransform: 'none',
        fontSize: '15px',
        p: '5px',
        width: '200px',
        '&:hover': {
          backgroundColor: '#008080',
          color: 'white',
        },
        textAlign:'center'
      }}
      onClick={handleClick}
    >
      {t('View')}
    </Button>
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
          </>
  );
}
