import React, { useEffect, useRef, useState } from "react";
import cornerstone from "cornerstone-core";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import dicomParser from "dicom-parser";
import JSZip from "jszip";
import LoadingPage from "../Components/LoadingPage";
import { Container } from "@mui/material";

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

const DicomViewer = () => {
  const [zipContent, setZipContent] = useState(null);
  const [imageIds, setImageIds] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [thumbnails, setThumbnails] = useState([]);
  const [loading, setLoading] = useState(true);
  const elementRef = useRef(null);
  const [d30, setD30] = useState("");

  useEffect(() => {
    setD30(localStorage.getItem("dicom"));
  }, []);

  const handleZipView = async () => {
    try {
      const response = await fetch(d30);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const zip = await JSZip.loadAsync(blob);
      setZipContent(zip);
    } catch (error) {
      console.error("Error fetching or loading zip file:", error);
    }
  };

  const loadDicomFile = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const dicomData = dicomParser.parseDicom(new Uint8Array(arrayBuffer));
      const numberOfFrames = dicomData.intString("x00280008") || 1;

      const frameImageIds = [];
      for (let i = 0; i < numberOfFrames; i++) {
        const imageId =
          cornerstoneWADOImageLoader.wadouri.fileManager.add(file);
        frameImageIds.push(`${imageId}?frame=${i}`);
      }

      // Store the first frame of each DICOM file as a thumbnail
      if (frameImageIds.length > 0) {
        const image = await cornerstone.loadImage(frameImageIds[0]);
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = image.width;
        canvas.height = image.height;
        cornerstone.renderToCanvas(canvas, image);
        const thumbnailDataUrl = canvas.toDataURL("image/png");

        setThumbnails((prevThumbnails) => [
          ...prevThumbnails,
          { thumbnailDataUrl, frameImageIds },
        ]);
      }

      return frameImageIds;
    } catch (error) {
      console.error("Error loading DICOM file:", error);
      return [];
    }
  };

  const loadDicomImages = async (imageIds) => {
    if (imageIds.length > 0 && elementRef.current) {
      try {
        cornerstone.enable(elementRef.current);
        const firstImageId = imageIds[currentImageIndex];
        const image = await cornerstone.loadImage(firstImageId);
        cornerstone.displayImage(elementRef.current, image);
      } catch (error) {
        console.error("Error loading DICOM images:", error);
      }
    }
  };

  const handleMouseWheel = (event) => {
    if (!elementRef.current) return;
    let newImageIndex = currentImageIndex + (event.deltaY > 0 ? 1 : -1);
    if (newImageIndex < 0) {
      newImageIndex = 0;
    } else if (newImageIndex >= imageIds.length) {
      newImageIndex = imageIds.length - 1;
    }

    setCurrentImageIndex(newImageIndex);
  };

  const handleThumbnailClick = (frameImageIds) => {
    setImageIds(frameImageIds);
    setCurrentImageIndex(0);  
  };

  useEffect(() => {
    handleZipView();
  }, [d30]);

  useEffect(() => {
    if (zipContent) {
      const loadDicomFiles = async () => {
        const ids = [];
        const thumbnailsArray = [];
        for (const fileName of Object.keys(zipContent.files)) {
          const file = await zipContent.files[fileName].async("blob");
          const frameImageIds = await loadDicomFile(file);
  
          if (frameImageIds.length > 0) {
            ids.push(frameImageIds[0]); 
  
            const image = await cornerstone.loadImage(frameImageIds[0]);
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.width = image.width;
            canvas.height = image.height;
            cornerstone.renderToCanvas(canvas, image);
            const thumbnailDataUrl = canvas.toDataURL("image/png");
  
            thumbnailsArray.push({ thumbnailDataUrl, frameImageIds });
          }
        }
        setImageIds(ids);  
        setThumbnails(thumbnailsArray);  
        setLoading(false);  
      };
  
      loadDicomFiles();
    }
  }, [zipContent]);

  useEffect(() => {
    if (imageIds.length > 0) {
      loadDicomImages(imageIds);
    }
  }, [imageIds, currentImageIndex]);

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.addEventListener("wheel", handleMouseWheel);
      return () => {
        element.removeEventListener("wheel", handleMouseWheel);
      };
    }
  }, [currentImageIndex, imageIds]);

  return (
    <div
      style={{
        backgroundColor: "black",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Container maxWidth="md" sx={{ bgcolor: "black" }}>
        <div
          ref={elementRef}
          onWheel={handleMouseWheel}
          style={{
            width: "100%",
            overflowY: "auto",
            position: "relative",
          }}
        >
          {loading && <LoadingPage open={loading} />}
          {!loading && (
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </div>
      </Container>

      <div style={{ marginTop: "10px", display: "flex", overflowX: "auto",width:'100%',flexWrap:'wrap',justifyContent:'center',alignItems:'center' }}>
        {thumbnails.map((thumbnail, index) => (
          <img
            key={index}
            src={thumbnail.thumbnailDataUrl}
            alt={`Thumbnail ${index}`}
            onClick={() => handleThumbnailClick(thumbnail.frameImageIds)}
            style={{
              cursor: "pointer",
              margin: "0 5px",
              height: "70px", 
              border: "2px solid white",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default DicomViewer;
