import { TextField, MenuItem, ThemeProvider, useTheme } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useState } from "react";

export default function TableField({
  value,
  onChange,
  width,
  h,
  type,
  data,
  place,
  handle,
  range
}) {
  const { min, max } = range || {}; 
  const outerTheme = useTheme();
  const [error, setError] = useState(false); 

  const handleChange = (e) => {
    let newValue = e.target.files ? e.target.files[0] : e.target.value;

    if (type === "number") {
      newValue = parseFloat(newValue); 
    }

    if (handle && (newValue < min || newValue > max)) {
      setError(true);
    } else {
      setError(false); 
      onChange(newValue); 
    }
  };

  const customTheme = (outerTheme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiInputLabel: {
          styleOverrides: {
            root: {
              color: "gray",
              height: h,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                border: error ? "2px solid red" : "1px solid #64C6C2", 
                height: h,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: error ? "2px solid red" : "1px solid #64C6C2", 
                height: h,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: error ? "2px solid red" : "1px solid #64C6C2", 
                height: h,
              },
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              "&::before, &::after": {
                borderBottomColor: error ? "red" : "#64C6C6",
                height: h,
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: error ? "red" : "#64C6C6",
                height: h,
              },
              "&.Mui-focused:after": {
                borderBottomColor: error ? "red" : "#64C6C6",
                height: h,
              },
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              "&::before": {
                borderBottomColor: error ? "red" : "#64C6C6",
                height: h,
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: error ? "red" : "#64C6C6",
                height: h,
              },
              "&.Mui-focused:after": {
                borderBottomColor: error ? "red" : "#64C6C6",
                height: h,
              },
            },
          },
        },
      },
    });

  return (
    <div style={{ marginTop: "10px" }}>
      <ThemeProvider theme={customTheme(outerTheme)}>
        <TextField
          error={error} 
          helperText={error && `Value must be between ${min} and ${max}`} 
          value={value}
          placeholder={place ? place : ""}
          type={type}
          select={data ? true : false}
          onChange={handleChange}
          sx={{
            backgroundColor: "white",
            width: width,
            height: h,
            ".MuiSelect-select": {
              display: "flex",
              flexDirection: "row",
              overflowX: "auto",
              gap: "20px",
              justifyContent: "start",
              alignItems: "center",
            },
            ".MuiInputBase-root": {
              height: h,
            },
            "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
              right: "10px",
              position: value ? "relative" : "absolute",
              color: value ? 'black' : ''
            },
            "& input[type='file']::-webkit-file-upload-button": {
              display: "none",
              opacity: 0,
            },
            "& input[type='file']::file-selector-button": {
              display: "none",
              opacity: 0,
            },
          }}
        >
          {data &&
            data.map((s) => (
              <MenuItem value={s.title} key={s.title}>
                {s.title}
              </MenuItem>
            ))}
        </TextField>
      </ThemeProvider>
    </div>
  );
}
