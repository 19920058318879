import React, { useState } from "react";
import { Box, Stack, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

function EchoDetails({requests}) {
  const matches = useMediaQuery("(min-width:600px)");
  const { t } = useTranslation();

  return (
    <Stack
      direction={matches ? "row" : "column"}
      sx={{ pt: "30px", mb: "30px", width: "100%", position: "relative" }}
    >
      <Box sx={{ width: "35%" }}>
        <p style={{ fontSize: "20px", fontWeight: "bolder", color: "#008080"}}>
          {requests.service}
        </p>
      </Box>
      <Box
        sx={{
          width: "65%",
          display: "flex",
          alignItems: matches ? "end" : "start",
          flexDirection: "column",
          justifyContent: "start",
          mr: "20px",
        }}
      >
        <Stack
          sx={{
            alignItems: "start",
            justifyContent: "start",
          }}
        >
          <Stack direction="row">
            <p
              style={{
                color: "#464646",
                fontSize: "13px",
                fontWeight: "bold",
                marginRight: "8px",
              }}
            >
              {t('Requested Date :')}
            </p>
            <p style={{ color: "#464646", fontSize: "13px" }}>{requests.request_date}</p>
          </Stack>

         {requests.service && <Stack direction="row">
            <p
              style={{
                color: "#464646",
                fontSize: "13px",
                fontWeight: "bold",
                marginRight: "8px",
              }}
            >
              {t('Service :')} 
            </p>
            <p style={{ color: "#464646", fontSize: "13px" }}>{requests.service}</p>
          </Stack>}

          <Stack direction="row">
            <p
              style={{
                color: "#464646",
                fontSize: "13px",
                fontWeight: "bold",
                marginRight: "8px",
              }}
            >
              {t('Status :')}
            </p>
            <p
             style={{ color: "#464646", fontSize: "13px" }}
            >
                {requests.status}
              {/* <a href={requests[0].report.attachment} target="_blank">
              {t('Download')}
              </a> */}
            </p>
          </Stack> 
        </Stack>
      </Box>
    </Stack>
  );
}

export default EchoDetails;
