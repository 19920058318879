import { Container, Stack, Button, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import TechDialog from "./TechDialog";

export default function TechsCard({ t }) {
  const matches = useMediaQuery("(min-width:1024px)");
  const matche = useMediaQuery("(min-width:670px)");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  
  const handleReadMore = (selectedData) => {
    setOpen(true);
    setData(selectedData);
  };

  return (
    <div style={{ zIndex: 10, backgroundColor: "white" }}>
      <Stack
        direction={matche ? "row" : "column"}
        sx={{
          alignItems: "center",
          border: "2px solid #64c6c2",
          p: matche ? "20px" : "10px",
          borderRadius: "20px",
          zIndex: 10,
          width: matches ? "90%" : "250px",
          minHeight: matches ? "180px" : "250px",
        }}
      >
        <img
          src={t.file}
          style={{ height: "100px", width: matches ? "90px" : "150px" }}
        />
        <Stack>
          <Container
            maxWidth="xs"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: matches ? "start" : "center",
              alignItems: "center",
            }}
          >
            <Stack>
              <p
                style={{
                  fontWeight: "bold",
                  color: "#6D6D6D",
                  fontSize: "15px",
                  textAlign: matches ? "" : "center",
                }}
              >
                {t.title}
              </p>
              {
              t.description !== null &&
              matches ? (
                <p style={{ color: "#6D6D6D" }}>{`${t.description.substring(
                  0,
                  90
                )} `}</p>
              ) :
              t.description !== null &&
              !matches ? (
                <p
                  style={{ color: "#6D6D6D", textAlign: "center" }}
                >{`${t.description.substring(0, 50)} `}</p>
              ): null }
            </Stack>
            { t.description !== null && t.description.length > 99 && (
              <Button
                sx={{ bgcolor: "transparent", color: "#64c6c2" }}
                onClick={() =>
                  handleReadMore({
                    file: t.file,
                    title: t.title,
                    text: t.description,
                  })
                }
              >
                read more
              </Button>
            )}
          </Container>
        </Stack>
      </Stack>
      <TechDialog open={open} setOpen={setOpen} data={data} />
    </div>
  );
}
