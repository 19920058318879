import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, Stack, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function ServiceCard({s}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const matches = useMediaQuery("(min-width:380px)");

    return (
    <div>
       <Card sx={{ width: matches?325:300 , border:'1px solid #64c6c2',borderRadius:'30px',boxShadow:'none' }}>
        <Stack sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
      <img
        style={{ height: '250px',width:matches?'300px':'250px', marginTop:'10px',objectFit:'cover',borderRadius:'30px' }}
        src={s.file}
      />
      <CardContent sx={{textAlign:'center'}}>
        <Container maxWidth='xs'>
        <Typography sx={{fontSize:'20px',textAlign:'center'}}>
          {s.title}
        </Typography>
          <Button sx={{color:'#64c6c2',textTransform:'none'}} onClick={()=>{navigate('/service', { state: { id: s.id } })}}>
            {t('View More')}
          </Button>
        </Container>
      </CardContent>
      </Stack>
    </Card>
    </div>
  )
}
