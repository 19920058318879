import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import TableField from "./TableField";
import { Box, Stack, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import FieldDisplay from "./FieldDisplay";

export default function ThiredTable({
  peakGradient,
  setPeakGradient,
  meanGradient,
  setMeanGradient,
  AVA,
  setAVA,
  LVOTVTI,
  setLVOTVTI,
  SVI,
  setSVI,
  JetArea,
  setJetArea,
  peakGradient2,
  setPeakGradient2,
  VenaContract,
  setVenaContract,
  RVOL,
  setRVOL,
  MVA,
  setMVA,
  ERO,
  setERO,
  TIG,
  setTIG,
  Stenosis,
  setStenosis,
  Regurgitation,
  setRegurgitation,
  meanGradient2,
  setMeanGradient2,
  show,
}) {
  const { type } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:800px)");

  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", width: "100%", direction: "ltr" }}
    >
      <Table
        sx={{
          ".MuiTableCell-root": {
            fontFamily: '"Quicksand", sans-serif',
            border: "1.5px solid #008080",
          },
        }}
      >
        <TableHead sx={{ bgcolor: "#008080" }}>
          <TableRow>
            <TableCell colSpan={4} sx={{ color: "white", fontSize: "15px" }}>
              {t("Values and Doppler")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              display: matches ? "" : "flex",
              flexDirection: "column",
              border: matches ? "" : "1.5px solid #008080",
              margin: matches ? "" : "8px 0",
            }}
          >
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: matches ? "none" : "1.5px solid #008080",
                borderRight: matches ? "1.5px solid #008080" : 0,
                alignContent: "start",
              }}
            >
              Aortic Valvle
              <Stack>
                <Stack direction="row" gap={2}>
                  <FieldDisplay
                    label="Peak Gradient"
                    value={peakGradient}
                    setValue={setPeakGradient}
                    show={show}
                    unit="mmHg"
                    type="number"
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                  <FieldDisplay
                    label="Mean Gradient"
                    value={meanGradient}
                    setValue={setMeanGradient}
                    show={show}
                    unit="mmHg"
                    type="number"
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                  <FieldDisplay
                    label="AVA"
                    value={AVA}
                    setValue={setAVA}
                    show={show}
                    unit="cm"
                    type="number"
                    range="(1.5 - 2.0cm)"
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                  <FieldDisplay
                    label="LVOT VTI"
                    value={LVOTVTI}
                    setValue={setLVOTVTI}
                    show={show}
                    unit="cm"
                    type="number"
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                  <FieldDisplay
                    label="SVI"
                    value={SVI}
                    setValue={setSVI}
                    show={show}
                    unit="cm"
                    type="number"
                  />
                </Stack>
              </Stack>
            </TableCell>

            {/*---------------------------- second column --------------------------------*/}
            <TableCell
              colSpan={2}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "none",
                alignContent: "start",
              }}
            >
              Mitral Valve
              <Stack>
                <Stack direction="row" gap={2}>
                  <FieldDisplay
                    label="Jet Area"
                    value={JetArea}
                    setValue={setJetArea}
                    show={show}
                    unit="cm"
                    type="number"
                  />
              
                    <FieldDisplay
                    label="Peak Gradient"
                    value={peakGradient2}
                    setValue={setPeakGradient2}
                    show={show}
                    unit="mmHg"
                    type="number"
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                   <FieldDisplay
                    label="Vena Contract"
                    value={VenaContract}
                    setValue={setVenaContract}
                    show={show}
                    type="number"
                  />
                 
                  <FieldDisplay
                    label="Mean Gradient"
                    value={meanGradient2}
                    setValue={setMeanGradient2}
                    show={show}
                    type="number"
                    unit='mmHg'
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                   <FieldDisplay
                    label="RVol"
                    value={RVOL}
                    setValue={setRVOL}
                    show={show}
                    type="number"
                    unit='ml'
                  />
                
                    <FieldDisplay
                    label="MVA"
                    value={MVA}
                    setValue={setMVA}
                    show={show}
                    type="number"
                    unit='cm'
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                    <FieldDisplay
                    label="ERO"
                    value={ERO}
                    setValue={setERO}
                    show={show}
                    type="number"
                    unit='mm2'
                  />
                </Stack>
              </Stack>
            </TableCell>
          </TableRow>

          {/*--------------------------------------------- second row first column */}
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: matches ? "1.5px solid #008080" : "0.5px solid #008080",
              },
              display: matches ? "" : "flex",
              flexDirection: "column",
              border: matches ? "" : "1.5px solid #008080",
              margin: matches ? "" : "8px 0",
            }}
          >
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "none",
                borderRight: "1.5px solid #008080",
                alignContent: "start",
              }}
            >
              Tricuspid Valve
              <Stack direction="row" gap={2}>
                 <FieldDisplay
                    label="TIG Gradient"
                    value={TIG}
                    setValue={setTIG}
                    show={show}
                    type="number"
                    unit='mmHg'
                  />
              </Stack>
            </TableCell>

            {/*-------------------------------------- second row second column */}

            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "none",
                alignContent: "start",
              }}
            >
              Plumonic Valve
              <Stack>
                <Stack direction="row" gap={2}>
                  <FieldDisplay
                    label="Regurgitation"
                    value={Regurgitation}
                    setValue={setRegurgitation}
                    show={show}
                    data={[
                      { title: "-" },
                      { title: "None" },
                      { title: "Trace(trivial)" },
                      { title: "Mild" },
                      { title: "Mild to moderate" },
                      { title: "Moderate" },
                      { title: "Moderate to Severe" },
                      { title: "Severe" },
                    ]}
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                    <FieldDisplay
                    label="Stenosis"
                    value={Stenosis}
                    setValue={setStenosis}
                    show={show}
                    data={[
                      { title: "-" },
                      { title: "None" },
                      { title: "Mild" },
                      { title: "Mild to Moderate" },
                      { title: "Moderate," },
                      { title: "Moderate to Severe" },
                      { title: "Severe" },
                    ]}
                  />
                </Stack>
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
