import { Alert, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TextFieldComp from "../Components/TextFieldComp";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../store/authSlice';
import Success from "../Components/Success";
import ContactIcon from "../Components/ContactIcon";

export default function Login() {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const windowHeight = React.useRef(window.innerHeight);
  const matches = useMediaQuery("(min-width:700px)");
  const navigate = useNavigate()
  const [alerting, setAlerting] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const dispatch = useDispatch();
  const {error} = useSelector((state) => state.auth)

  const isEmail = (input) => /\S+@\S+\.\S+/.test(input);

  const handleClick = () => {
    if (password && name) {
      const loginPayload = isEmail(name)
        ? { email: name, password: password }
        : { username: name, password: password };
      dispatch(login(loginPayload))
      .then((result) => {
        if (result.payload.status) {
          const userType = result.payload.data.user.role;  
          setAlerting(false)
          setOpenSuccess(true)
          setTimeout(() => {
          if(userType === 'technician' || userType === 'supervisor' ||userType === 'doctor'){
            navigate('/mainpage')
            window.location.reload(true)
            localStorage.setItem('btn' ,"Patients")
          }
          else{
            navigate('/report')
            window.location.reload(true)
            localStorage.setItem('btn' ,"Report")
          }
          }, 2100);
        }
         else {
          setAlerting(true);
        }
      })
      .catch(() => {
        alert('Something went wrong please try again later');
      });}
      else{
        setAlerting(true)
      }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F4F4F4",
        display: "block",
        justifyContent: "center",
        minHeight: '100vh',
        alignItems: "center",
        padding: matches ? "100px" : "10px",
        marginTop:'100px'
      }}
      onKeyPress={handleKeyPress}
    >
      <Typography
        sx={{
          color: "#64c6c2",
          fontSize: "30px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {t("Sign In")}
      </Typography>

      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <TextFieldComp
            place={"User Name"}
            onChange={setName}
            value={name}
            h={"50px"}
            width={"100%"}
            bg={"white"}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <TextFieldComp
            place={"Password"}
            onChange={setPassword}
            value={password}
            h={"50px"}
            width={"100%"}
            bg={"white"}
            type={"password"}
          />

<Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "end",
          alignItems: "end",
        }}
      >
          <Button
            sx={{
              backgroundColor:'transparent',
              color: "#3D3D3D",
              textTransform: "none",
              width:'fit-content',
              mt:'10px',
              fontSize:'15px'
            }}
            onClick={()=>{navigate('/forgotpassword')}}
          >
           {t('Forgot Password?')}
          </Button>
        </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
      {alerting && <Alert severity="error" sx={{ width: matches ? "95%" : "90%",
                    marginTop: "20px",
                    mb: "20px",}}>      
           {!password || !name ? 
          'All fields must be filled' : error
          }
            </Alert>
            }
            </Grid>
            </Grid>
      
      
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Button
            sx={{
              backgroundImage: "linear-gradient(to right, #64C6C6 , #008080)",
              color: "white",
              borderRadius: "10px",
              textTransform: "none",
              fontSize: "15px",
              p: "5px",
              width: "100%",
              mt: "20px",
            }}
            onClick={handleClick}
          >
            {t('Sign In')}
          </Button>
        </Grid>
      </Grid>

      <Success
        open={openSuccess}
        setOpen={setOpenSuccess}
        msg={
          `Loged in successfully as ${localStorage.getItem('type')}`
        }
      />
      <ContactIcon />
    </div>
  );
}
