import React from "react";
import logo from "../Media/logo.png";
import { Box, Stack, TableCell } from "@mui/material";

export default function PDFContent({ data, details }) {

  return (
    <div
      id="pdf-content"
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "238mm",
        minHeight: "297mm",
        padding: "10mm",
        boxSizing: "border-box",
      }}
    >
      <img src={logo} alt="logo" style={{ width: "150px" }} />
      <div className="reusable">Patient Details</div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "250px",
          width:'90%'
        }}
      >
        <div style={{ gap: "7px", display: "flex", flexDirection: "column" }}>
          <p>
            Patient Name: <strong>{data && data.patient}</strong>
          </p>
          <p>
            Patient Gender: <strong>{details && details[2].gender}</strong>
          </p>
          <p>
            Requested Service: <strong>{data && data.service}</strong>
          </p>
          <p>
            Technician Name: <strong>{data && data.technician}</strong>
          </p>
          <p>
            Patient Birthday: <strong>{details && details[0].birthdate}</strong>
          </p>
        </div>
        <div style={{ gap: "7px", display: "flex", flexDirection: "column" }}>
          <p>
            Patient ID: <strong>{details && details[3].id_number}</strong>
          </p>
          <p>
            Request Date: <strong>{data && data.request_date}</strong>
          </p>
          <p>
            Test Date: <strong>{data && data.start_date}</strong>
          </p>
          <p>
            Supervisor Name: <strong>{data && data.supervisor}</strong>
          </p>
        </div>
      </div>
      <div className="reusable">General Information</div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          width: "90%",
        }}
      >
        <p>
          Referring Physician:{" "}
          <strong>{data && data.report.referring_physician}</strong>
        </p>
        <p>
          Indication: <strong>{data && data.report.indication}</strong>
        </p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "100px",
          }}
        >
          <p>
            HR: <strong>{data && data.report.hr}</strong>
          </p>
          <p>
            BP:{" "}
            <strong>
              {data && data.report.bp_1} / {data && data.report.bp_2}
            </strong>
          </p>
          <p>
            SPO2: <strong>{data && data.report.spo2}</strong>
          </p>
        </div>
      </div>
      <div className="reusable">Cardiac Dimensions and Functions</div>
      <table style={{ width: "92%", border:'none'}}>
        <tr>
          <TableCell
            component="th"
            scope="row"
            sx={{
              border: "1px solid black",
              alignContent: "start",
              border:'none'
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "1px solid black",
                margin: "0px",
              }}
            >
              Aorta Root
            </p>
            <Stack>
              <p>
                Aorta Root: <strong>{data && data.report.functions.aorta_root}</strong>
                <span style={{ color: "#6FD2A1" , fontWeight:'bold'}}> (2.0-3.7) cm</span>
              </p>

              <p>
              Ascending Aorta : <strong>{data && data.report.functions.aorta_ascending}</strong>
                <span style={{ color: "#6FD2A1" , fontWeight:'bold'}}> {`(<4.0) cm`}</span>
              </p>

              <p>
              Aorta Arch: <strong>{data && data.report.functions.aorta_arch}</strong>
                <span style={{ color: "#6FD2A1" , fontWeight:'bold'}}> {`(<3.5) cm`}</span>
              </p>

              <p>
              IVC: <strong>{data && data.report.functions.aorta_ivc}</strong>
              </p>
            </Stack>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              border: "1px solid black",
              alignContent: "start",
              border:'none'
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "1px solid black",
                margin: "0px",
              }}
            >
             Left Atrium
            </p>
            <Stack>
              <p>
              LA Area: <strong>{data && data.report.functions.left_atrium_la_area}</strong>
                <span style={{ color: "#6FD2A1" , fontWeight:'bold'}}> {`(<20) cm`}</span>
              </p>
              <p>
              LA Volume: <strong>{data && data.report.functions.left_atrium_la_volume}</strong>
                <span style={{ color: "#6FD2A1" , fontWeight:'bold'}}>(22-52) ml</span>
              </p>
              <p>
              Volume Index: <strong>{data && data.report.functions.left_atrium_volume_index}</strong>
              </p>

              <p>
              PSLAV: <strong>{data && data.report.functions.left_atrium_pslav}</strong>
                <span style={{ color: "#6FD2A1" , fontWeight:'bold'}}> (1.9-4.0) cm</span>
              </p>
            </Stack>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              border: "1px solid black",
              alignContent: "start",
              border:'none'
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "1px solid black",
                margin: "0px",
              }}
            >
             Right Atrium
            </p>
            <Stack>
              <p>
              Size: <strong>{data && data.report.functions.right_atrium_size}</strong>
              </p>
              <p>
              Area: <strong>{data && data.report.functions.right_atrium_area}</strong>
              </p>
            </Stack>
          </TableCell>
        </tr>



        <tr>
        <TableCell
         colSpan={2}
            component="th"
            scope="row"
            sx={{
              border: "1px solid black",
              alignContent: "start",
              border:'none'
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "1px solid black",
                margin: "0px",
              }}
            >
             Left Venricle
            </p>
            <Stack>
            <Stack direction="row" gap={2}>
              <p>
              EDd: <strong>{data && data.report.functions.left_ventrical_edd}</strong>
                <span style={{ color: "#6FD2A1" , fontWeight:'bold'}}> (3.7-5.6) cm</span>
              </p>
              <p>
              LVPWD: <strong>{data && data.report.functions.left_ventrical_lvpwd}</strong>
              </p>
              </Stack>
             

              <Stack direction="row" gap={2}>
              <p>
              EDs: <strong>{data && data.report.functions.left_ventrical_eds}</strong>
                <span style={{ color: "#6FD2A1" , fontWeight:'bold'}}> (2.7-3.4) cm</span>
              </p>
              <p>
              LVPWS: <strong>{data && data.report.functions.left_ventrical_lvpws}</strong>
              </p>
              </Stack>

              <Stack direction="row" gap={2}>
              <p>
              IVSDd: <strong>{data && data.report.functions.left_ventrical_ivsdd}</strong>
                <span style={{ color: "#6FD2A1" , fontWeight:'bold'}}> (0.6-1.2) cm</span>
              </p>
              <p>
              E-Wave: <strong>{data && data.report.functions.left_ventrical_ewave}</strong>
              </p>
              </Stack>

              <Stack direction="row" gap={2}>
              <p>
              IVSSd: <strong>{data && data.report.functions.left_ventrical_ivssd}</strong>
                <span style={{ color: "#6FD2A1" , fontWeight:'bold'}}> (0.8-2.0) cm</span>
              </p>
              <p>
              A-Wave: <strong>{data && data.report.functions.left_ventrical_awave}</strong>
              </p>
              </Stack>

              <Stack direction="row" gap={2}>
              <p>
              EF: <strong>{data && data.report.functions.left_ventrical_ef}</strong>
                <span style={{ color: "#6FD2A1" , fontWeight:'bold'}}> (50-70)% </span>
              </p>
              <p>
              E/A: <strong>{data && data.report.functions.left_ventrical_ea}</strong>
              </p>
              </Stack>
            </Stack>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              border: "1px solid black",
              alignContent: "start",
              border:'none'
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "1px solid black",
                margin: "0px",
              }}
            >
             Right Ventrical
            </p>
            <Stack>
              <p>
              ED Diameter: <strong>{data && data.report.functions.right_ventrical_ed_diameter}</strong>
              <span style={{ color: "#6FD2A1" , fontWeight:'bold'}}> (0.5-2.6) cm </span>
              </p>
            </Stack>
          </TableCell>
        </tr>
      </table>

      <div className="reusable">Values and Doppler</div>
      <table style={{ width: "92%",  border:'none'}}>
        <tr>
          <TableCell
            component="th"
            scope="row"
            sx={{
              borderBottom: "1px solid black",
              alignContent: "start",
              border:'none'
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "1px solid black",
                margin: "0px",
              }}
            >
               Aortic Valve
            </p>
            <Stack>
              <p>
              Peak Gradient : <strong>{data && data.report.valves.aortic_peak_gradient}</strong>
              </p>

              <p>
              Mean Gradient : <strong>{data && data.report.valves.aortic_mean_gradient}</strong>
              </p>

              <p>
              AVA : <strong>{data && data.report.valves.aortic_ava}</strong>
                <span style={{ color: "#6FD2A1" , fontWeight:'bold'}}> (1.5 - 2.0cm)</span>
              </p>

              <p>
              LVOT VTI : <strong>{data && data.report.valves.aortic_lvotvti}</strong>
              </p>
              <p>
              SVI : <strong>{data && data.report.valves.aortic_svi}</strong>
              </p>
            </Stack>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              border: "1px solid black",
              alignContent: "start",
              border:'none'
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "1px solid black",
                margin: "0px",
              }}
            >
             Plumonic Valve
            </p>
            <Stack>
              <p>
              Regurgitation: <strong>{data && data.report.valves.pulmonic_regurgitation}</strong>
              </p>
              <p>
              Stenosis: <strong>{data && data.report.valves.pulmonic_stenosis}</strong>
              </p>
            </Stack>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              border: "1px solid black",
              alignContent: "start",
              border:'none'
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "1px solid black",
                margin: "0px",
              }}
            >
             Tricuspid Valve
            </p>
            <Stack>
              <p>
              TIG Gradient: <strong>{data && data.report.valves.tricuspid_tig_gradient}</strong>
              </p>
            </Stack>
          </TableCell>
        </tr>



        <tr>
        <TableCell
         colSpan={3}
            component="th"
            scope="row"
            sx={{
              border: "1px solid black",
              alignContent: "start",
              border:'none'
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                borderBottom: "1px solid black",
                margin: "0px",
              }}
            >
             Mitral Valve
            </p>
            <Stack>
            <Stack direction="row" gap={5}>
              <p>
              Jet Area: <strong>{data && data.report.valves.mitral_jet_area}</strong>
              </p>
              <p>
              RVol: <strong>{data && data.report.valves.mitral_rvol}</strong>
              </p>
              <p>
              Peak Gradient: <strong>{data && data.report.valves.mitral_peak_gradient}</strong>
              </p>
              </Stack>
             

              <Stack direction="row" gap={5}>
              <p>
              Vena Contract: <strong>{data && data.report.valves.mitral_vena_contract}</strong>
              </p>
              <p>
              ERO: <strong>{data && data.report.valves.mitral_ero}</strong>
              </p>
              <p>
              Mean Gradient: <strong>{data && data.report.valves.mitral_mean_gradient}</strong>
              </p>
              </Stack>
            </Stack>
          </TableCell>
        </tr>
      </table>

      <div className="reusable">Summary and Conclusion</div>
      <Stack sx={{alignContent: "start", width:'90%'}}>
       <p><strong>Left Ventricle:</strong>{data&& data.report.summary.left_atrium_size} Size and
       {data&& data.report.summary.left_ventricle_function} Function and {data&& data.report.summary.left_ventricle_lvh} LVH.
       {data&& data.report.summary.left_ventricle_field_1}{data&& data.report.summary.left_ventricle_field_2}{data&& data.report.summary.left_ventricle_field_3}</p>  


        <p><strong>Diastolic Function: </strong>
        {data&& data.report.summary.diastolic_function} 
        dysfunction.</p> 


        <p><strong>Right Ventricle:</strong>{data&& data.report.summary.right_ventricle_size} Size and
       {data&& data.report.summary.right_ventricle_function} Function.</p> 

       <p><strong>Left Atrium: </strong>
        {data&& data.report.summary.left_atrium_size} 
        Size.</p> 

        <p><strong>Mitral Valve:</strong>{data&& data.report.summary.mitral_valve} Valve with
       {data&& data.report.summary.mitral_leaflet} leaflet {data&& data.report.summary.mitral_regurgitation} Regurgitation and
       {data&& data.report.summary.mitral_stenosis} Stenosis 
       {data&& data.report.summary.mitral_field}</p>  


       <p><strong>Aortic Valve:</strong>{data&& data.report.summary.aortic_valve}
       {data&& data.report.summary.aortic_field_1} and {data&& data.report.summary.aortic_field_2} and 
       {data&& data.report.summary.aortic_regurgitation} Regurgitation and 
       {data&& data.report.summary.aortic_stenosis} Stenosis  {data&& data.report.summary.aortic_field_3}</p>  


       <p><strong>Tricuspid Valve:</strong>{data&& data.report.summary.tricuspid_valve} and
       {data&& data.report.summary.tricuspid_regurgitation}  Regurgitation and {data&& data.report.summary.tricuspid_stenosis} Stenosis and 
       {data&& data.report.summary.tricuspid_pulmonary} Pulmonary Hypertension (TIG)
       {data&& data.report.summary.tricuspid_tig}</p> 


       <p><strong>Pulmonary Valve:</strong>{data&& data.report.summary.pulmonary_valve} </p> 
       <p>There is {data&& data.report.summary.pericardial_effusion} pericardial effusion</p> 
       <p><strong>Extra Notes:</strong>{data&& data.report.summary.extra_notes} </p> 

       </Stack>    

       <div style={{ width: '90%', height: '1px', backgroundColor: 'black', margin: '0 auto', marginTop:'10px' }}></div>
       <Stack direction='row'  sx={{
                        displa: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                        mt:'10px'
                      }}
                      gap={55}>
      <Box><p><strong>Technician:</strong>{data && data.technician}</p></Box>
      <Box><p><strong>Approved By:</strong>{data && data.supervisor}</p></Box>
      </Stack>
    </div>
  );
}
