import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import TableField from "./TableField";
import { useSelector } from "react-redux";

export default function GeneralTable({
  physician,
  setPhysician,
  indication,
  setIndication,
  hr,
  setHr,
  bp1,
  setBp1,
  bp2,
  setBp2,
  spo2,
  setSpo2,
  show,
}) {
  const { t } = useTranslation();
  const { type } = useSelector((state) => state.auth);

  return (
    <TableContainer
      component={Paper}
      sx={{ width: "100%", boxShadow: "none", direction: "ltr" }}
    >
      <Table
        sx={{
          border: "1.5px solid #008080",
          ".MuiTableCell-root": { fontFamily: '"Quicksand", sans-serif' },
        }}
      >
        <TableHead sx={{ bgcolor: "#008080" }}>
          <TableRow>
            <TableCell colSpan={4} sx={{ color: "white", fontSize: "15px" }}>
              {t("General")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!show && physician ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                Referring Physician
              </TableCell>
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
                colSpan={3}
              >
                <p className="p_text">{physician}</p>
              </TableCell>
            </TableRow>
          ) : null}

          {show ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                Referring Physician
              </TableCell>
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
                colSpan={3}
              >
                <TableField
                  onChange={setPhysician}
                  value={physician}
                  h={"40px"}
                  width={"100%"}
                  type={"text"}
                />
              </TableCell>
            </TableRow>
          ) : null}

          {!show && indication ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                Indication
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <p className="p_text">{indication}</p>
              </TableCell>
            </TableRow>
          ) : null}

          {show ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                Indication
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <TableField
                  onChange={setIndication}
                  value={indication}
                  h={"40px"}
                  width={"100%"}
                  type={"text"}
                />
              </TableCell>
            </TableRow>
          ) : null}

          {!show && hr ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                HR
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <p className="p_text">{hr}</p>
              </TableCell>
            </TableRow>
          ) : null}

          {show ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                HR
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <TableField
                  handle={true}
                  onChange={setHr}
                  value={hr}
                  h={"40px"}
                  width={"100%"}
                  type={"number"}
                  range={{ min: 1, max: 170 }}
                />
              </TableCell>
            </TableRow>
          ) : null}

          {!show && bp1 && bp2 ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                BP
              </TableCell>
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" , display:'flex',flexDirection:'row', gap:'20px'}}
              >
                <p className="p_text">{bp1}</p>
                <p style={{ fontWeight: "bolder" }}>/</p>
                <p className="p_text">{bp2}</p>
              </TableCell>
            
            </TableRow>
          ) : null}

          {show ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                BP
              </TableCell>
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <TableField
                  handle={true}
                  range={{ min: 1, max: 250 }}
                  onChange={setBp1}
                  value={bp1}
                  h={"40px"}
                  width={"100%"}
                  type={"number"}
                />
              </TableCell>
              <TableCell
                align="center"
                sx={{ alignItems: "center", borderBottom: "none" }}
              >
                <p style={{ fontWeight: "bolder" }}>/</p>
              </TableCell>
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <TableField
                  handle={true}
                  range={{ min: 0, max: 180 }}
                  onChange={setBp2}
                  value={bp2}
                  h={"40px"}
                  width={"100%"}
                  type={"number"}
                />
              </TableCell>
            </TableRow>
          ) : null}

          {!show && spo2 ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                SPO2
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <p className="p_text">{spo2}</p>
              </TableCell>
            </TableRow>
          ) : null}
          {show ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                SPO2
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <TableField
                 handle={true}
                 range={{ min: 1, max: 100 }}
                  onChange={setSpo2}
                  value={spo2}
                  h={"40px"}
                  width={"100%"}
                  type={"number"}
                />
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
