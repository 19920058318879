import { Container, Stack, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import vector from '../Media/Group.png'


export default function Story({story}) {
    const { t } = useTranslation();
    const matches = useMediaQuery("(min-width:920px)");

  return (
    <div  style={{ display: "flex", alignItems: "center", justifyContent: "center" , marginTop:'100px',padding:matches?'':'20px'}} >
        <div style={{ border: "1px solid #64c6c2", borderRadius:'20px',padding:'30px',
        width:matches?'fit-content':'90%',position:'relative'}}>
{matches &&            <img src={vector} style={{position:'absolute',right:'30px'}}/>
}      <Stack direction='row' 
sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap:'wrap' }} gap={1}>
      <Typography sx={{ color: "#64c6c2", fontSize: "30px" }}>
          {t("The Story of Nairuz: ")}
        </Typography>
        <Typography sx={{ fontSize: "30px" }}>
          {t("Get to Know Us")}
        </Typography>
      </Stack>


        <Stack direction='row' style={{marginTop:'30px',flexWrap:'wrap',display: "flex", alignItems: "center", justifyContent: "center"}}>
        <img src={story.file} style={{width:matches?'400px':'280px'}}/>
        <Container maxWidth='xs'>
            <p style={{fontSize:'15px',color:'#6D6D6D',fontFamily:'"Quicksand", sans-serif'}}>
              {story.description}
            </p>
        </Container>
        </Stack>
      </div>
    </div>
  )
}
