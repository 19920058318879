import { TextField, MenuItem, InputAdornment, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { useState } from "react";

export default function SecondTextFieldComp({
  label,
  value,
  onChange,
  width,
  h,
  type,
  data,
  im,
  dataObj,
  end,
}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [emailError, setEmailError] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    if (type === "file") {
      const file = e.target.files[0];
      onChange(file);
    } else {
      const newValue = e.target.value;
      setEmailError(type === "email" && !validateEmail(newValue));
      onChange(newValue);
    }
  };

  const customTheme = (outerTheme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiInputLabel: {
          styleOverrides: {
            root: {
              color: "gray",
              height: h,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                height: h,
                border:'none'
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                height: h,
                border:'none'
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                height: h,
                border:'none'
              },
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              "&::before, &::after": {
                borderBottomColor: "#64C6C6",
                height: h,
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#64C6C6",
                height: h,
              },
              "&.Mui-focused:after": {
                borderBottomColor: "#64C6C6",
                height: h,
              },
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              "&::before": {
                borderBottomColor: "#64C6C6",
                height: h,
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#64C6C6",
                height: h,
                border: "1px solid #008080",
              },
              "&.Mui-focused:after": {
                borderBottomColor: "#64C6C6",
                height: h,
              },
            },
          },
        },
      },
    });

  const outerTheme = useTheme();

  return (
    <div style={{ marginTop: "10px" }}>
      <label
        style={{
          color: "#008080",
          fontSize: "15px",
          fontWeight:'bolder'
        }}
      >
        {t(label)}
      </label>
      <br />

      <ThemeProvider theme={customTheme(outerTheme)}>
        <TextField
          SelectProps={{
            value: value ,
            multiple: dataObj ? true : false,
          }}
          type={type}
          select={data ? true : dataObj ? true : false}
          onChange={(e) => handleChange(e)}
          error={type === "email" && emailError}
          helperText={
            type === "email" && emailError
              ? "Please enter a valid email address"
              : ""
          }
          sx={{
            bgcolor:'white',
            width: width,
            letterSpacing: "0px",
            height: h,
            mt: "5px",
            borderRadius: "10px",
            "& input[type='file']::-webkit-file-upload-button": {
              display: "none",
              opacity:0
            },
            "& input[type='file']::file-selector-button": {
              display: "none",              opacity:0

            },
            ".MuiInputBase-input": { height: h , color:type === 'file' &&value.length === 0 ? 'transparent' : ''
            ,outline:'none',border:'none'},
            ".MuiInputBase-root ": { height: h, borderRadius: "10px" },
            ".MuiSvgIcon-root": { right: lang === "en" ? "7px" : "90%" },
            ".MuiSelect-select": {
              display: "flex",
              flexDirection: "row",
              overflowX: "auto",
              gap: "20px",
              justifyContent:'start', 
              alignItems:'center'
            },
            border:'none',
            outline:'none'
          }}
         
        >
          {data &&
            data.map((s) => (
              <MenuItem value={s.id}>
                {s.file &&  <img
                    src={s.file}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "100%",
                      margin:'10px'

                    }}
                  />} {s.title}
              </MenuItem>
            ))}
          {dataObj &&
            dataObj.map((s) => (
              <MenuItem value={s.id}>
                <Stack direction="row" gap={1}>
                  {s.file && <img
                    src={s.file}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "100%",
                      margin:'10px'
                    }}
                  />}
                  <p style={{ color: "#3D3D3D" }}>{s.title}</p>
                </Stack>
              </MenuItem>
            ))}
        </TextField>
      </ThemeProvider>
    </div>
  );
}
