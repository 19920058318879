import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, Grid, Stack, useMediaQuery } from "@mui/material";
import SecondTextFieldComp from "../Components/SecondTextFieldComp";
import { Container } from "@mui/system";
import pic2 from "../Media/attach.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import LoadingPage from "../Components/LoadingPage";
import Success from "../Components/Success";
import ButtonBar from "../Components/ButtonBar";
import ContactIcon from "../Components/ContactIcon";
import { useNavigate } from "react-router-dom";

export default function AddPatient() {
  const windowHeight = React.useRef(window.innerHeight);
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:700px)");
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [id, setId] = useState("");
  const [gender, setGender] = useState("");
  const [genderList, setGenderList] = useState([
    { id: "male", title: "Male" },
    { id: "female", title: "Female" },
  ]);
  const [birthday, setBirthday] = useState("");
  const [neededService, setNeededService] = useState("");
  const [neededServicelist, setNeededServiceList] = useState([]);
  const [attachement, setAttachement] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [secondphone, setSecondPhone] = useState("");
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [open, setOpen] = useState(false);
  const [alerting, setAlerting] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [btnArray, setBtnArray] = useState(["Patients", "Profile"]);
  const navigate = useNavigate ()
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API_URL}services-title`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setNeededServiceList(res.data.data);
        setOpen(false);
      });
  }, []);

  const handleSend = () => {
    if (
      name &&
      lastname &&
      id &&
      gender &&
      neededService &&
      birthday &&
      email &&
      phone 
    ) {
      const formData = new FormData();
      formData.append("first_name", name);
      formData.append("last_name", lastname);
      formData.append("id_number", id);
      formData.append("gender", gender);
      formData.append("service_id", neededService);
      formData.append("birthdate", birthday);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("second_phone", secondphone);
      {
        attachement && formData.append("file", attachement);
      }
      formData.append("doctor_id", localStorage.getItem("id"));

      axios.post(`${process.env.REACT_APP_API_URL}patient-form`,formData, {
        headers: {
          "Accept-Language": lang,
        },
      }).then(
        res=>{
          if(res.data.status === true){
            setOpenSuccess(true)
            setTimeout(() => {
              navigate('/mainpage')
              }, 2100);
          }
      }
      )
  
    } else {
      setAlerting(true);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F4F4F4",
        minHeight: windowHeight.current,
        paddingBottom: "50px",
      }}
    >
      <ButtonBar btnArray={btnArray} />
      {open && <LoadingPage open={open} />}

      {!open && (
        <>
          <Container
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              position: "relative",
            }}
            maxWidth="md"
          >
            <Grid container spacing={1} sx={{ mt: "30px" }}>
              <Grid item xs={12} sm={6}>
                <SecondTextFieldComp
                  label={"First Name"}
                  onChange={setName}
                  value={name}
                  h={"50px"}
                  width={"80%"}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SecondTextFieldComp
                  label={"Last Name"}
                  onChange={setLastName}
                  value={lastname}
                  h={"50px"}
                  width={"80%"}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <SecondTextFieldComp
                  label={"Gender"}
                  onChange={setGender}
                  value={gender}
                  h={"50px"}
                  width={"80%"}
                  data={genderList}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SecondTextFieldComp
                  label={"Birthday"}
                  onChange={setBirthday}
                  value={birthday}
                  h={"50px"}
                  width={"80%"}
                  type={"date"}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <SecondTextFieldComp
                  label={"Needed Service"}
                  onChange={setNeededService}
                  value={neededService}
                  h={"50px"}
                  width={"80%"}
                  data={neededServicelist}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SecondTextFieldComp
                  label={"Referral Attachment (Optional)"}
                  onChange={setAttachement}
                  value={attachement}
                  h={"50px"}
                  width={"80%"}
                  type={"file"}
                  end={pic2}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <SecondTextFieldComp
                  label={"Email"}
                  onChange={setEmail}
                  value={email}
                  h={"50px"}
                  width={"80%"}
                  type={"email"}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SecondTextFieldComp
                  label={"ID Number"}
                  onChange={setId}
                  value={id}
                  h={"50px"}
                  width={"80%"}
                  type={"number"}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <label
                  style={{
                    color: "#3D3D3D",
                    fontSize: "15px",
                  }}
                >
                  {t("Phone Number")}
                </label>
                <PhoneInput
                  country={"jo"}
                  enableSearch={true}
                  value={phone}
                  onChange={setPhone}
                  style={{ marginTop: "5px" }}
                  inputStyle={{
                    backgroundColor: "white",
                    width: "80%",
                    color: "black",
                    fontSize: "13px",
                    height: "50px",
                    outline: "none",
                    border: "none",
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <label
                  style={{
                    color: "#3D3D3D",
                    fontSize: "15px",
                  }}
                >
                  {t("Second Phone Number")}
                </label>
                <PhoneInput
                  country={"jo"}
                  enableSearch={true}
                  value={secondphone}
                  onChange={setSecondPhone}
                  style={{ marginTop: "5px" }}
                  inputStyle={{
                    backgroundColor: "white",
                    width: "80%",
                    color: "black",
                    fontSize: "13px",
                    height: "50px",
                    outline: "none",
                    border: "none",
                  }}
                />
              </Grid>
            </Grid>

            <Stack>
              {alerting && (
                <Alert
                  severity="error"
                  sx={{
                    width: matches ? "310px" : "280px",
                    marginTop: "20px",
                    mb: "20px",
                  }}
                >
                  {t("All fields must be filled.")}
                </Alert>
              )}
              <Button
                sx={{
                  backgroundColor: "#008080",
                  color: "white",
                  textTransform: "none",
                  fontSize: "15px",
                  p: "5px",
                  width: "200px",
                  mt: "40px",
                  "&:hover": {
                    backgroundColor: "#008080",
                    color: "white",
                  },
                }}
                onClick={handleSend}
              >
                {t("Submit")}
              </Button>
            </Stack>
          </Container>
        </>
      )}

      <Success
        open={openSuccess}
        setOpen={setOpenSuccess}
        msg={"Your request has been successfully send."}
      />
      <ContactIcon />
    </div>
  );
}
