import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const RequireAuth = ({children, role}) => {

    const isAuth = useSelector(state => state.auth.Authinticate)
    const type = useSelector(state => state.auth.type)

    if(!isAuth){
        return <Navigate to="/login" />
    }
    else if (isAuth && role.includes(type)){
        return children
    }
}

export default RequireAuth
