import React, { useEffect, useState } from "react";
import ButtonBar from "../Components/ButtonBar";
import { Alert, Box, Button, CardContent, Container, useMediaQuery } from "@mui/material";
import SearchComponent from "../Components/SearchComponent";
import CardsComponent from "../Components/CardsComponent";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingPage from "../Components/LoadingPage";
import ContactIcon from "../Components/ContactIcon";

export default function TechnicianPage() {
  const { type } = useSelector((state) => state.auth);
  const matches = useMediaQuery("(min-width:1024px)");
  const token = localStorage.getItem('token');
  const [searchText, setSearchText] = useState("");
  const windowHeight = React.useRef(window.innerHeight);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(true);
  const [alerting, setAlerting] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API_URL}get-related-requests`, {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      }).then(res => {
        setData(res.data.data.patients);
        setFilteredData(res.data.data.patients);
        setOpen(false);
      });
  }, []);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSearch = () => {
    const filtered = data.filter(d => d.full_name.toLowerCase().includes(searchText.toLowerCase()));
    setFilteredData(filtered);
    if(filtered.length === 0){
      setAlerting(true)
    }
    else{
      setAlerting(false)
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F4F4F4",
        minHeight: windowHeight.current,
        paddingBottom: "50px",
      }}
    >
      {open && <LoadingPage open={open} />}
      <ButtonBar />
      {!open && <Container maxWidth="lg">
        <SearchComponent
          searchText={searchText}
          setSearchText={setSearchText}
          onSearch={handleSearch}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          {type === "doctor" && (
            <Button
              sx={{
                backgroundColor: "#008080",
                color: "white",
                textTransform: "none",
                fontSize: "15px",
                p: "5px",
                width: "200px",
                mt: "30px",
                "&:hover": {
                  backgroundColor: "#008080",
                  color: "white",
                },
              }}
              onClick={() => {
                navigate("/addpatient");
              }}
            >
              {t("+ Add Patient")}
            </Button>
          )}
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: matches ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)',
            gap: "30px",
            width: "100%",
          }}
        >
          {filteredData.map((d) =>
            <CardsComponent d={d} key={d.id} />
          )}

{alerting && (
            <Alert
              severity="error"
              sx={{
                width: matches ? "95%" : "90%",
                marginTop: "20px",
                mb: "20px",
              }}
            >
              {t('No useres found.')}
            </Alert>
          )}
        </Box>
      </Container>}

      <ContactIcon />
    </div>
  );
}
