import React, { useEffect, useState } from "react";
import ButtonBar from "../Components/ButtonBar";
import { Alert, Button, Container, useMediaQuery } from "@mui/material";
import SecondTextFieldComp from "../Components/SecondTextFieldComp";
import pic2 from "../Media/attachement.png";
import { useTranslation } from "react-i18next";
import axios from "axios";
import LoadingPage from "../Components/LoadingPage";
import { useSelector } from "react-redux";
import Success from "../Components/Success";
import ContactIcon from "../Components/ContactIcon";
import { useNavigate } from "react-router-dom";

export default function RequestNewService() {
  const windowHeight = React.useRef(window.innerHeight);
  const [service, setservice] = useState("");
  const [attachement, setAttachement] = useState("");
  const [services, setServices] = useState([]);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const [alerting, setAlerting] = useState(false);
  const matches = useMediaQuery("(min-width:700px)");
  const { type } = useSelector((state) => state.auth);
  const [openSuccess, setOpenSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const lang = localStorage.getItem("lang");
    axios
      .get(`${process.env.REACT_APP_API_URL}services-title`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setServices(res.data.data);
        setOpen(false);
      });
  }, []);

  const handleSubmit = () => {
    setAlerting(false);
    const token = localStorage.getItem("token");
    const lang = localStorage.getItem("lang");
    if (service && type === "doctor") {
      setAlerting(false);
      const formData = new FormData();
      formData.append("patient_id", localStorage.getItem("patient_id"));
      formData.append("service_id", service);
      formData.append("file", attachement);
      formData.append("doctor_id", localStorage.getItem("id"));
      axios
        .post(`${process.env.REACT_APP_API_URL}requset-by-patient`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": lang,
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            setOpenSuccess(true);
            setAlerting(false);
            setTimeout(() => {
              navigate("/mainpage");
            }, 2100);
          }
        });
    } else if (service && type === "patient") {
      setAlerting(false);
      const formData = new FormData();
      formData.append("patient_id", localStorage.getItem("id"));
      formData.append("service_id", service);
      formData.append("file", attachement);
      axios
        .post(`${process.env.REACT_APP_API_URL}requset-by-patient`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": lang,
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            setOpenSuccess(true);
            setAlerting(false);
            setTimeout(() => {
              navigate('/report')
            }, 2100);
          }
        });
    } else {
      setAlerting(true);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F4F4F4",
        minHeight: windowHeight.current,
        paddingBottom: "50px",
      }}
    >
      <ButtonBar />
      {open && <LoadingPage open={open} />}

      {!open && (
        <Container maxWidth="lg" sx={{ mt: "50px" }}>
          <SecondTextFieldComp
            label={"Needed Service"}
            onChange={setservice}
            value={service}
            width={"100%"}
            data={services}
          />

          <SecondTextFieldComp
            label={"Referral Attachment (Optional)"}
            onChange={setAttachement}
            value={attachement}
            width={"100%"}
            type={"file"}
            end={pic2}
          />
          {alerting && (
            <Alert
              severity="error"
              sx={{
                width: matches ? "420px" : "310px",
                marginTop: "20px",
                mb: "20px",
              }}
            >
              {t("All fields must be filled.")}
            </Alert>
          )}
          <Button
            sx={{
              backgroundColor: "#008080",
              color: "white",
              textTransform: "none",
              fontSize: "15px",
              p: "5px",
              width: "200px",
              mt: "40px",
              "&:hover": {
                backgroundColor: "#008080",
                color: "white",
              },
            }}
            onClick={handleSubmit}
          >
            {t("Submit")}
          </Button>
        </Container>
      )}

      <Success
        open={openSuccess}
        setOpen={setOpenSuccess}
        msg={"Your request has been successfully send."}
      />
      <ContactIcon />
    </div>
  );
}
