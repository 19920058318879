import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Avatar, Container, useMediaQuery } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";


export default function PatientDetails({details}) {
    const matches = useMediaQuery("(min-width:700px)");   
    const { t } = useTranslation();

    return (
    <div style={{marginTop:'30px'}}>
            <Card
        sx={{
          display: "flex",
          p: "30px",
          minWidth: matches ? "" : "80%",
          boxShadow:'none', 
        }}
      >
          <CardContent sx={{ display: "flex", flexDirection: "row" }}>
            <Stack
              direction={matches ? "row" : "column"}
              gap={matches ? 10 : 2}
              sx={{ display: "flex", flexWrap: "wrap",justifyContent:'center',alignItems:'start' }}
            >
               <Avatar />
            
              <Stack sx={{gap:'10px'}} >
                <Typography variant="h5" sx={{ color: "#008080" , fontWeight:'bold'}}>
                  {t('Patient Details')} 
                </Typography>
                <Typography sx={{ color: "#464646",fontSize:'15px' }}>
                {t('User Name :')} {details[1].first_name} {details[4].last_name}
                    </Typography>
                    <Typography sx={{ color: "#464646",fontSize:'15px' }}>
                {t('Gender :')} {details[2].gender}
                    </Typography> 
                    <Typography sx={{ color: "#464646",fontSize:'15px' }}>
                {t('Birthday :')} {details[0].birthdate}
                    </Typography> 
              </Stack>


              <Stack gap={1}>
                    <Typography sx={{ color: "#464646",fontSize:'15px' }}>
                {t('Phone Number :')} {details[5].phone}
                    </Typography> 


                    <Typography sx={{ color: "#464646",fontSize:'15px' }}>
                {t('ID Number :')} {details[3].id_number}
                    </Typography>     
                    </Stack>

                            

            </Stack>
          </CardContent>
      </Card>
    </div>
  )
}
