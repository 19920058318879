import { Container, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import pic from '../Media/Group.png'
import WhyUsCard from "./WhyUsCard";

export default function WhyUs({whyUs, featureDescription}) {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:700px)");

  return (
    <div style={{position:'relative',marginTop:matches?'100px':'100px'}} id="Why Us?">
{      matches &&  <img src={pic} style={{position:'absolute',top:'100px',left:'100px'}} />
}      <Stack
        direction="row"
        gap={1}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Typography sx={{ fontSize: "30px",textAlign:'center' }}>{t("Why People")} </Typography>
        <Typography sx={{ color: "#64c6c2", fontSize: "30px" ,textAlign:'center'}}>
          {t("Choose Us")}
        </Typography>
      </Stack>
      <Container maxWidth='sm'>
                <p style={{color:'#B0B0B0', textAlign: "center" }}>
                  {featureDescription}
                </p>
            </Container>
    <Container sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',gap:'20px',mt:'100px'}}>
    {whyUs.map((q,i)=>
    <WhyUsCard q={q} i={i} />
    )}
    </Container>
    </div>
  );
}
