import React from 'react'
import {
    Button,
    Container,
    Stack,
    Typography,
    useMediaQuery,
  } from "@mui/material";
  import pic from "../Media/Group.png";
  import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function Partner({partnerDescription}) {
    const { t } = useTranslation();
    const matches = useMediaQuery("(min-width:700px)");
    const navigate = useNavigate();


  return (
    <div id="Become a Partner">
         <div >
            <Stack
              direction="row"
              gap={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: "100px",
                flexWrap: "wrap",
              }}
            >
              <Typography sx={{ fontSize: "30px", textAlign: "center" }}>
                {t("Become a part")}
              </Typography>
              <Typography
                sx={{ color: "#64c6c2", fontSize: "30px", textAlign: "center" }}
              >
                {t("of Our Network")}
              </Typography>
            </Stack>
          </div>
          <Container maxWidth="sm" sx={{ textAlign: "center" }}>
            <p style={{ color: "#B0B0B0" }}>{partnerDescription}</p>
          </Container>
          {matches && (
            <img src={pic} style={{ left: "100px", position: "absolute" }} />
          )}{" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{
                backgroundImage: "linear-gradient(to right, #64C6C6 , #008080)",
                color: "white",
                borderRadius: "10px",
                textTransform: "none",
                fontSize: "20px",
                p: "15px",
                minWidth: "150px",
                mt: "20px",
              }}
              onClick={() => {
                navigate("/joinus");
              }}
            >
              {t("Fill the Form")}
              <ArrowCircleRightIcon sx={{ m: "5px", fontSize: "20px" }} />
            </Button>
          </div>
    </div>
  )
}
