import React from "react";
import { Stack, Box } from "@mui/material";
import TableField from "./TableField";

const FieldDisplay = ({ label, range, value, setValue, show, unit, type, data }) => {
  const parseRange = (range) => {
    if (!range) return { min: null, max: null };

    let min = null;
    let max = null;

    const rangeTrimmed = range.replace(/[^\d<>\.-]+/g, '');

    if (rangeTrimmed.includes('-')) {
      const match = rangeTrimmed.match(/([\d.]+)\s*-\s*([\d.]+)/);
      if (match) {
        min = parseFloat(match[1]);
        max = parseFloat(match[2]);
      }
    } else if (rangeTrimmed.startsWith('<')) {
      max = parseFloat(rangeTrimmed.replace('<', '').trim());
    } else if (rangeTrimmed.startsWith('>')) {
      min = parseFloat(rangeTrimmed.replace('>', '').trim());
    }

    return { min, max };
  };

  const { min, max } = parseRange(range);

  const isWithinRange = (min !== null && value >= min) && (max !== null && value <= max);

  const textColor = !range
    ? '#F94A4A' 
    : isWithinRange || (min === null && value <= max) || (max === null && value >= min)
    ? '#0EAD27' 
    : '#F94A4A'; 

  return (
    <>
      {value && !show ? (
        <>
          {label && (
            <Box sx={{ width: "20%" }}>
              <p style={{ fontSize: "10px" }}>
                {label}
                <br />
                <p style={{ color: "#0EAD27" }}>{range}</p>
              </p>
            </Box>
          )}
          <p
            style={{
              color: textColor,
              fontSize: '10px',
              fontWeight: 'bold',
            }}
          >
            {value}
          </p>
        </>
      ) : null}

      {show ? (
        <Stack direction="row" gap={2} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <>
            <p style={{ fontSize: "10px" }}>{label}
              <br />
              <p style={{ color: "#0EAD27" }}>{range}</p>
            </p>
            <TableField
              onChange={setValue}
              value={value}
              h={"40px"}
              width={"100%"}
              place={unit}
              type={type}
              data={data}
            />
          </>
        </Stack>
      ) : null}
    </>
  );
};

export default FieldDisplay;
