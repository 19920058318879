import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Alert, Avatar, useMediaQuery } from "@mui/material";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function CardsComponent({ d }) {
  const matches = useMediaQuery("(min-width:1024px)");
  const navigate = useNavigate();
  const [view, setView] = React.useState(false);
  const { type } = useSelector((state) => state.auth);
  const [al, setal] = React.useState(1);

  React.useEffect(() => {
    if (al === 1) {
      setView(false);
    } else {
      setView(true);
    }
  }, []);
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        flexWrap: "wrap",
        marginTop: "30px",
      }}
    >
      {(type === "technician" && d.is_filling_form === true) ||
      (type === "supervisor" && d.is_needs_approval === true) ? (
        <Alert
          severity="info"
          sx={{
            width: "fit-content",
            color: "white",
            bgcolor: "#008080",
            ".MuiAlert-icon": { color: "white" },
            fontFamily: '"Quicksand", sans-serif',
            fontSize: "12px",
            borderRadius: "10px",
          }}
        >
          {type === "technician"
            ? "Report Needs Filling"
            : type === "supervisor"
            ? "Report Needs Approval"
            : null}
        </Alert>
      ) : null}
      <Card
        sx={{
          display: "flex",
          p: "30px",
          minWidth: matches ? "80%" : "80%",
          boxShadow: "none",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/report", { state: { data: d.id } });
        }}
      >
        <Avatar sx={{ mt: "20px" }} />

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent>
            <Stack gap={2} sx={{ display: "flex", flexWrap: "wrap" }}>
              <Typography variant="h5" sx={{ color: "#008080" }}>
                {t('Patient Name :')} {d.full_name}
              </Typography>
              <Typography sx={{ color: "#464646" }}>
                {d.gender}, {d.birthdate}
              </Typography>

              <Typography sx={{ color: "#464646" }}>
                {t('ID Number :')} {d.id_number}
              </Typography>

              <Typography sx={{ color: "#464646" }}>
                {t('Email :')} {d.email}
              </Typography>
            </Stack>
          </CardContent>
        </Box>
      </Card>
    </div>
  );
}
