import React, { useState } from "react";
import {
  Box,
  Container,
  Stack,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css/navigation";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function Introduction({ slider }) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const matches = useMediaQuery("(min-width:1000px)");

  const swiperStyles = `
  @media (max-width: 500px) {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #64c6c2;
  }
`;
  return (
    <div id="Home">
            <style>{swiperStyles}</style>
      <Swiper
        modules={[Autoplay,Navigation]} 
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        loop
        slidesPerView={1}
        navigation
      >
        {slider.map((s) => (
          <SwiperSlide
            style={{
              position: "relative",
              backgroundImage: `url(${s.file})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "800px",
              display:matches?'':'flex',
              alignItems:matches?'':'center',
              justifyContent:matches?'':'center'
              
            }}
          >
            <Box
              style={{
                backdropFilter: "blur(15px)",
                backgroundColor: "#D8D3D766",
                position: "absolute",
                bottom: "100px",
                left: matches ? "13%" : "",
                minHeight: "300px",
                width: matches ? "400px" : "80%",
                padding: matches ? "40px" : "10px",            
              }}
            >
              <Container maxWidth="xs" sx={{  display:matches?'':'flex',
                alignItems:matches?'':'center',
                justifyContent:matches?'':'center',
                flexDirection:matches?'':'column'}}>
                <Stack >
                  <Typography sx={{ fontSize: "30px",color:'#008080' }}>
                    {s.title}
                  </Typography>
                </Stack>
                <p>
                 {s.description}
                </p>
                <Button
                  sx={{
                    backgroundImage:"linear-gradient(to right, #64C6C6 , #008080)",
                    color: "white",
                    minWidth: "fit-content",
                    height: "fit-content",
                    textTransform: "none",
                    fontSize: "20px",
                    mt: "30%",
                    textAlign:'center'
                  }}
                  href={s.button_action} target="_blank"
                >
                 {s.button_text}{" "}
                  {lang === 'en' ? <ArrowForwardIosIcon sx={{ ml: "10px" }} /> : <ArrowBackIosIcon sx={{ mr: "10px" }}/>}
                </Button>
              </Container>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
