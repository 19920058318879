import { Alert, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextFieldComp from "../Components/TextFieldComp";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Success from "../Components/Success";
import ContactIcon from "../Components/ContactIcon";

export default function ResetPassword() {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const windowHeight = React.useRef(window.innerHeight);
  const matches = useMediaQuery("(min-width:700px)");
  const navigate = useNavigate();
  const location = useLocation();
  const [alerting, setAlerting] = useState(false);
  const [secondalerting, setSecondAlerting] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
   

  const currentPageUrl = window.location.href;
  let email;
  let token;
  const hashIndex = currentPageUrl.indexOf('#');
  if (hashIndex !== -1) {
    const hashFragment = currentPageUrl.substring(hashIndex + 1);
    const queryIndex = hashFragment.indexOf('?');
    if (queryIndex !== -1) {
      const queryParameters = new URLSearchParams(hashFragment.substring(queryIndex + 1));
      email = queryParameters.get('email');
      token = queryParameters.get('token');
    }
  }

  const handleClick = () => {
    if (password && confirmPassword) {
      setAlerting(false);
      setSecondAlerting(false);
      if (password === confirmPassword) {
        const formData = new FormData();
        formData.append("password", password);
        formData.append("password_confirmation", confirmPassword);
        formData.append("email", email);
        formData.append("token", token);
        axios.post(`${process.env.REACT_APP_API_URL}password/reset`, formData).then(res => {

          if (res.data.status === true) {
            setOpenSuccess(true);
            setTimeout(() => {
              navigate('/login');
            }, 2100);
          }
        });
      } else {
        setSecondAlerting(true);
      }
    } else {
      setAlerting(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };


  return (
    <div
      style={{
        backgroundColor: "#F4F4F4",
        display: "block",
        justifyContent: "center",
        minHeight: windowHeight.current,
        alignItems: "center",
        padding: matches ? "100px" : "10px",
        marginTop:'100px'
      }}
      onKeyPress={handleKeyPress}
    >
      <Typography
        sx={{
          color: "#64c6c2",
          fontSize: "30px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {t("Reset Password")}
      </Typography>

      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <TextFieldComp
            place={"New Password*"}
            onChange={setPassword}
            value={password}
            h={"50px"}
            width={"100%"}
            bg={"white"}
            type={"password"}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <TextFieldComp
            place={"Confirm New Password*"}
            onChange={setConfirmPassword}
            value={confirmPassword}
            h={"50px"}
            width={"100%"}
            bg={"white"}
            type={"password"}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          {alerting && (
            <Alert
              severity="error"
              sx={{
                width: matches ? "95%" : "90%",
                marginTop: "20px",
                mb: "20px",
              }}
            >
              {t('All fields must be filled')}
            </Alert>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          {secondalerting && (
            <Alert
              severity="error"
              sx={{
                width: matches ? "95%" : "90%",
                marginTop: "20px",
                mb: "20px",
              }}
            >
              {t('The passwords do not match.')}
            </Alert>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Button
            sx={{
              backgroundImage: "linear-gradient(to right, #64C6C6 , #008080)",
              color: "white",
              borderRadius: "10px",
              textTransform: "none",
              fontSize: "15px",
              p: "5px",
              width: "100%",
              mt: "20px",
            }}
            onClick={handleClick}
          >
            {t("Reset Password")}
          </Button>
        </Grid>
      </Grid>

      <Success
        open={openSuccess}
        setOpen={setOpenSuccess}
        msg={
          "Password changed successfully."
        }
      />
      <ContactIcon />
    </div>
  );
}
