import { Box, Container, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function SecondFooter() {
  const matches = useMediaQuery('(min-width:985px)');
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#F2FAFF',
        color: 'white',
        py: 2,
      }}
    >
      <Container maxWidth="xl">
        <Stack
          direction={matches ? 'row' : 'column'}
          gap={matches ? 30 : 2}
          sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}
        >
          <Typography variant="body2" sx={{ fontSize: '15px',color:'#3D3D3D' }}>
            {t('CopyRight')}  {new Date().getFullYear()}, {t('All Rights Reserved')}
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '15px',color:'#3D3D3D'  }}>
          <Link
                onClick={(event) => {
                  event.preventDefault();
                  window.open('#/privacypolicy', '_blank');
                }}
                sx={{ color:'#3D3D3D' , fontSize: '15px', cursor: 'pointer',textDecoration:'none' }}
              >
                {t("Privacy Policy")}
              </Link>
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '15px',color:'#3D3D3D'  }}>
            {t("Powered By")}{' '}
            <Link target="_blank"
              href="https://smartedge.me"
              sx={{  fontSize: '15px', textDecoration: 'none', fontWeight: 'bold',color:'#3D3D3D'  }}
            >
              {t("SmartEdge")}
            </Link>
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}