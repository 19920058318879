import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import logo from "./Media/logo.png";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";



function SecondNavbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const lang = localStorage.getItem("lang");
  const { t, i18n } = useTranslation();
  const [lan, setLan] = React.useState("");
  document.body.dir = i18n.dir();
  const navigate = useNavigate()

  React.useEffect(() => {
    if (lang === "ar") {
      setLan(false);
    } else {
      setLan(true);
    }
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleEn = () => {
    i18next.changeLanguage("en");
    localStorage.setItem("lang", "en");
    setLan(true);
    window.location.reload(true);
  };

  const handleAr = () => {
    i18next.changeLanguage("ar");
    localStorage.setItem("lang", "ar");
    setLan(false);
    window.location.reload(true);
  };

  const handleLogOut =() =>{
    setAnchorElNav(null);
    localStorage.removeItem('Authinticate')
    localStorage.removeItem('token')
    localStorage.removeItem('type')
    localStorage.removeItem('btn')
    localStorage.removeItem('id')
    localStorage.removeItem('name')
    localStorage.removeItem('patient_id')
    localStorage.removeItem('dicom')
    navigate('/')
  }

  return (
    <AppBar position="fixed" sx={{ p: "20px", bgcolor: "white", boxShadow: "none" , borderBottom:'2px solid #008080'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography variant="h6" noWrap component="a" href="/">
            <img src={logo} style={{ height: "60px" }} />
          </Typography>

          <Box
            sx={{
              flexGrow: 10,
              display: { xs: "flex", md: "none" },
              justifyContent: "end",
            }}
          >
            <IconButton
              size="large"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
                 <IconButton
              sx={{ p: '10px', "&:hover": { bgcolor: "transparent" }, gap: "10px" }}
            >
              <Avatar />
              <div>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#008080",
                    fontWeight: "bold",
                    marginTop: "20px",
                    textAlign: "start",
                  }}
                >
                 {localStorage.getItem('name')}

                </p>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#464646",
                    fontWeight: "bold",
                    textAlign: "start",
                    textTransform:'capitalize'
                  }}
                >
                {localStorage.getItem('type')}
                </p>
              </div>
            </IconButton>

              {lan && (
                <Button
                  sx={{
                    my: 2,
                    display: "block",
                    letterSpacing: "0px",
                    color: "black",
                    textTransform: "none",
                    fontSize: "13px",
                    fontFamily: '"Almarai", sans-serif',
                  }}
                  onClick={handleAr}
                >
                  العربية
                </Button>
              )}
              {!lan && (
                <Button
                  sx={{
                    my: 2,
                    display: "block",
                    color: "black",
                    textTransform: "none",
                    fontSize: "13px",
                    fontFamily: '"League Spartan", sans-serif',
                  }}
                  onClick={handleEn}
                >
                  English
                </Button>
              )}

              <div
                style={{
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <LogoutIcon
                  sx={{ color: "black", cursor: "pointer" }}
                  onClick={handleLogOut}
                />
              </div>
            </Menu>
          </Box>

          <Box
            sx={{ flexGrow: 0.88, display: { xs: "none", md: "flex" } }}
          ></Box>

          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "none", md: "flex" },
              gap: "40px",
            }}
          >
            <IconButton
              sx={{ p: 0, "&:hover": { bgcolor: "transparent" }, gap: "10px" , cursor:'auto'}}
            >
              <Avatar />
              <div>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#008080",
                    fontWeight: "bold",
                    marginTop: "20px",
                    textAlign: "start",
                  }}
                >
                 {localStorage.getItem('name')}
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#464646",
                    fontWeight: "bold",
                    textAlign: "start",
                    textTransform:'capitalize'
                  }}
                >
                 {localStorage.getItem('type')}
                </p>
              </div>
            </IconButton>

            {lan && (
              <Button
                sx={{
                  my: 2,
                  display: "block",
                  letterSpacing: "0px",
                  color: "black",
                  textTransform: "none",
                  fontSize: "13px",
                  fontFamily: '"Almarai", sans-serif',
                }}
                onClick={handleAr}
              >
                العربية
              </Button>
            )}
            {!lan && (
              <Button
                sx={{
                  my: 2,
                  display: "block",
                  color: "black",
                  textTransform: "none",
                  fontSize: "13px",
                  fontFamily: '"League Spartan", sans-serif',
                }}
                onClick={handleEn}
              >
                English
              </Button>
            )}

            <div
              style={{
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <LogoutIcon
                sx={{ color: "black", cursor: "pointer" }}
                onClick={handleLogOut}
              />
            </div>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default SecondNavbar;
