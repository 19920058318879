import { Box, Button, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

export default function ButtonBar({}) {
  const type = localStorage.getItem('type')
  const { t } = useTranslation();
  const [btnArray, setBtnArray] = React.useState(
    type === "technician" || type === "supervisor"
      ? [t("Patients"), t("Pending Reports"), t("Profile")]
      : type === "patient"
      ? [t("Reports"), t("Request a New Service"), t("Profile")]
      : type === "doctor"
      ? [t("Patients"), t("Profile")]
      : ['']);
  const matches = useMediaQuery("(min-width:760px)");
  const navigate = useNavigate()
  const handleBtnClick = (btn) => {
        localStorage.setItem('btn',btn)
        let pageInEnglish;
        switch (btn) {
          case t('Patients'):
            pageInEnglish = 'mainpage';
            break;
            case t('Pending Reports'):
            pageInEnglish = 'pendingreports';
            break;
          case t('Profile'):
            pageInEnglish = 'profile';
            break;
            case t('Request a New Service'):
            pageInEnglish = 'requestnewservice';
            break;
            case t('Reports'):
            pageInEnglish = 'report';
            break;
          default:
            pageInEnglish = btn;
            break;
        }
        if (pageInEnglish === 'Patients') {
          navigate('/mainpage');
        } 
        else if (pageInEnglish === 'Pending Reports'){
          navigate('/pendingreports');
        }
        else if (pageInEnglish === 'Profile'){
          navigate('/profile');
        }
        else if (pageInEnglish === 'Request a New Service'){
          navigate('/requestnewservice');
        }
        else if (pageInEnglish === 'Reports'){
          navigate('/report');
        }
        else{
          navigate(`/${pageInEnglish}`);
        }
  };


  return (
    <div
      style={{ boxShadow: "0 3px 2px -2px #A09C9C", backgroundColor: "white" ,marginTop:'120px'}}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          gap: matches ? "200px" : "10px",
          padding: "20px",
        }}
      >
        {btnArray.map((btn) => (
          <Button
            sx={{
              borderRadius: "20px",
              border: localStorage.getItem('btn') === btn ? "1px solid #008080" : "none",
              color: "#008080",
              p: "10px",
              fontWeight: "bold",
              textTransform: "none",
              fontSize: matches ? "15px" : "",
            }}
            onClick={()=>{handleBtnClick(btn); 
            }}
          >
            {t(btn)}
          </Button>
        ))}
      </Box>
    </div>
  );
}
