import React, { useEffect, useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LoadingPage from "./LoadingPage";
import { useMediaQuery } from "@mui/material";

export default function ContactIcon() {
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const matches = useMediaQuery("(min-width:800px)");
  const [number, setNumber] = useState(localStorage.getItem('number'));

  return (
    <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'
    ,alignItems:'center',gap:'10px',position:'fixed',zIndex:10,
   top:matches?'85%':'89%',right:matches?'10px':'12px'}}>
      {number && (
        <a target="_blank" href={`https://wa.me/${number}`}>
          <WhatsAppIcon
            sx={{
              color: "#32B929",
              fontSize: matches ? "60px" : "40px",
              cursor: "pointer",
            }}
          />
        </a>
      )}
    </div>
  );
}
