import {
  Alert,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TextFieldComp from "../Components/TextFieldComp";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Success from "../Components/Success";
import ContactIcon from "../Components/ContactIcon";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const windowHeight = React.useRef(window.innerHeight);
  const matches = useMediaQuery("(min-width:700px)");
  const navigate = useNavigate();
  const [alerting, setAlerting] = useState(false);
  const [secalerting, setSecAlerting] = useState(false);
  const [msg, setMsg] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleClick = () => {
    if (email) {
      const formData = new FormData();
      formData.append("email", email);
      axios
        .post(`${process.env.REACT_APP_API_URL}password/email`, formData)
        .then(
          res=>{
            if(res.data.status === true){
              setOpenSuccess(true)
              setSecAlerting(false)
              setAlerting(false)
              setTimeout(() => {
                navigate('/')
                }, 2100);
            }
        }
        )
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            setSecAlerting(true);
            setMsg(error.response.data.message);
          }
        });
    } else {setAlerting(true);}
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F4F4F4",
        display: "block",
        justifyContent: "center",
        minHeight: windowHeight.current,
        alignItems: "center",
        padding: matches ? "100px" : "10px",
        marginTop:'100px'
      }}
    >
      <Typography
        sx={{
          color: "#64c6c2",
          fontSize: "30px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
        onKeyPress={handleKeyPress}
      >
        {t("Forgot Password")}
      </Typography>
      <Container
        maxWidth="sm"
        sx={{
          textAlign: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <p style={{ color: "#3D3D3D" }}>
          {t("Enter your email to receive a reset link")}
        </p>
      </Container>

      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <TextFieldComp
            place={"Email Address"}
            onChange={setEmail}
            value={email}
            h={"50px"}
            width={"100%"}
            bg={"white"}
            type={"email"}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          {alerting && (
            <Alert
              severity="error"
              sx={{
                width: matches ? "95%" : "90%",
                marginTop: "20px",
                mb: "20px",
              }}
            >
              {t("Email field must be filled")}
            </Alert>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          {secalerting && (
            <Alert
              severity="error"
              sx={{
                width: matches ? "95%" : "90%",
                marginTop: "20px",
                mb: "20px",
              }}
            >
              {msg}
            </Alert>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Button
            sx={{
              backgroundImage: "linear-gradient(to right, #64C6C6 , #008080)",
              color: "white",
              borderRadius: "10px",
              textTransform: "none",
              fontSize: "15px",
              p: "5px",
              width: "100%",
              mt: "20px",
            }}
            onClick={handleClick}
          >
            {t("Reset Password")}
          </Button>
        </Grid>
      </Grid>

      <Success
        open={openSuccess}
        setOpen={setOpenSuccess}
        msg={
          "Your request has been successfully send. Please check your email to continue"
        }
      />
      <ContactIcon />
    </div>
  );
}
