import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from "react-i18next";

export default function Success({ open, setOpen , msg}) {
  const { t } = useTranslation();
  let timeout;
  React.useEffect(() => {
    clearTimeout(timeout);
    if (open) {
      timeout = setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  }, [open, setOpen]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        maxWidth='sm'
        sx={{p:'40px'}}
      >
        <DialogContent sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
          {t(msg)}
          <CheckCircleOutlineIcon sx={{ color: 'lightgreen', marginLeft: '5px', height: '20px', width: '20px' }} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}