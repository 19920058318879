import { Button, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import pic from "../Media/Vector2.png";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "../App.css";

export default function Faqs({faq, faqDescription}) {
  const [q, setQ] = useState(faq[0].category.faqs);
  const [selected,setSelected] = useState('')
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:760px)");
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(()=>{
    setSelected(faq[0].category.title)
  },[])
  const handleAccordionChange = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <div style={{ marginTop: "100px", position: "relative" }} id="FAQs">
      <Stack
        direction="row"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap:'wrap' }}
        gap={1}
      >
        <Typography sx={{ fontSize: "30px",textAlign:'center' }}>{t("We’re Here to")}</Typography>
        <Typography sx={{ color: "#64c6c2", fontSize: "30px",textAlign:'center' }}>
          {t("Answer All Your Questions")}
        </Typography>
      </Stack>
      <Container maxWidth="sm">
        <p style={{ color: "#B0B0B0", textAlign: "center" }}>
          {faqDescription}
        </p>
      </Container>

      <img src={pic} style={{ position: "absolute", left: "10%" }} />

      <Stack
        direction="row"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          mt: "30px",
        }}
        gap={2}
      >
      { faq.map((c,index)=> <Button
          sx={{
            backgroundImage: selected === faq[index].category.title ?"linear-gradient(to right, #64C6C6 , #008080)":'transparent',
            color: selected === faq[index].category.title ?"white":'black',
            borderRadius: "10px",
            textTransform: "none",
            fontSize: "13px",
            p: "10px",
            width: "150px",
            border:selected === faq[index].category.title ? '' :'1px solid #008080'
          }}
          onClick={()=>{setQ(faq[index].category.faqs);
            setSelected(faq[index].category.title)
          }}
        >
          {c.category.title}
        </Button>)}
      </Stack>
<Container maxWidth='md'>
      <div className="scroll-container">
        {q.map((q, index) => (
          <Accordion
            key={index}
            elevation={0}
            disableGutters
            expanded={expandedIndex === index}
            onChange={() => handleAccordionChange(index)}
            classes={{ root: "accordion-root" }}
            sx={{
              bgcolor: "transparent",
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              "&:before": { display: "none" },
            }}
          >
            <AccordionSummary
              expandIcon={
                expandedIndex === index ? (
                  <RemoveIcon sx={{ color: "#64C6C6" }} />
                ) : (
                  <AddIcon sx={{ color: "#64C6C6" }} />
                )
              }
              sx={{
                bgcolor: "transparent",
                borderRadius: "20px",
                "&:before": { display: "none" },
                padding: "10px",
                width: matches ? "680px" : "100%",
                border: "1px solid #64C6C6",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                {q.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                borderBottom: "1px solid #64C6C6",
                borderRight: "1px solid #64C6C6",
                borderLeft: "1px solid #64C6C6",
                borderRadius: "10px",
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center'    ,
               width: matches ? "650px" : "250px",

              }}
            >
              <Container maxWidth="sm">
               {q.file && q.file_type === 'image/jpeg' ? <img
                  src={q.file}
                  style={{ width: "100%", objectFit: 'cover',height:'300px' }}
                /> : null }
                 {q.file && q.file_type === 'video/mp4' ? <video
                  src={q.file}
                  style={{ width: "100%", objectFit: 'contain',height:'300px' }}
                />: null }
                <Typography sx={{ color: "#B0B0B0", textAlign: 'center', marginTop: '10px' }}>
                  {q.answer}
                </Typography>
              </Container>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      </Container>
    </div>
  );
}
