import {
  Box,
  Container,
  Divider,
  Link,
  Stack,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import logo from "./Media/logo.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingPage from "./Components/LoadingPage";

export default function Footer() {
  const [lan, setLan] = React.useState("");
  const lang = localStorage.getItem("lang");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [general, setGeneral] = useState();
  const [services, setServices] = useState([]);
  const [open, setOpen] = useState(true);
  const matches = useMediaQuery("(min-width:500px)");

  React.useEffect(() => {
    if (lang === "ar") {
      setLan(false);
    } else {
      setLan(true);
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}home`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setGeneral(res.data.data.general);
        setServices(res.data.data.services);
        setOpen(false);
      });
  }, []);

  const handleEn = () => {
    i18next.changeLanguage("en");
    localStorage.setItem("lang", "en");
    setLan(true);
    window.location.reload(true);
  };

  const handleAr = () => {
    i18next.changeLanguage("ar");
    localStorage.setItem("lang", "ar");
    setLan(false);
    window.location.reload(true);
  };

  return (
    <div style={{ backgroundColor: "#F2FAFF" }}>
      {open && <LoadingPage open={open} />}
      {!open && (
        <footer>
          <Container sx={{ pt: "40px" }}>
            <Stack
              direction="row"
              sx={{ display: "flex", flexWrap: "wrap",
                alignItems: matches?"start":'center',
                justifyContent: matches?'start':"center",
              }} 
              gap={matches?12:5}
            >
              <Stack   sx={{ display:matches?'': "flex", flexWrap: matches?"":'wrap',
                alignItems: matches?"start":'center',
                justifyContent: matches?'':"center",
              }} >
                <Typography variant="h6" noWrap component="a" href="/">
                  <img src={logo} style={{ width: "200px" }} />
                </Typography>
                <p style={{ fontSize: "15px" ,textAlign:matches?'start':'center'}}>
                  {t("Experience personalized medical")} <br />
                  {t("care from the comfort of your home.")}
                </p>
              </Stack>

              {general.phone && general.email && (
                <Stack sx={{ mt: "20px" }}>
                  <Typography sx={{ color: "#64C6C6", fontSize: "15px" }}>
                    {t("Contact Us")}
                  </Typography>
                  <p style={{ color: "#6D6D6D", fontSize: "15px" }}>
                    {general.phone}
                  </p>
                  <p style={{ color: "#6D6D6D", fontSize: "15px" }}>
                    {general.email}
                  </p>
                </Stack>
              )}

              <Stack sx={{ mt: "20px" }} gap={1}>
                <Typography sx={{ color: "#64C6C6", fontSize: "15px" }}>
                  {t("Services")}
                </Typography>
                {services.map((s) => (
                  <Link
                    style={{
                      color: "#6D6D6D",
                      fontSize: "15px",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/service", { state: { id: s.id } })
                     window.location.reload(true)
                      
                    }}
                  >
                    {s.title}
                  </Link>
                ))}
              </Stack>

              <Stack sx={{ mt: "20px" }}>
                <Typography sx={{ color: "#64C6C6", fontSize: "15px" }}>
                  {t("Legal")}
                </Typography>
                <p
                  style={{
                    color: "#6D6D6D",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/privacypolicy");
                  }}
                >
                  {t("Privacy Policy")}
                </p>
              </Stack>

              <Stack sx={{ mt: "20px" }}>
                <Typography sx={{ color: "#64C6C6", fontSize: "15px" }}>
                  {t("Language")}
                </Typography>
                {lan && (
                  <Button
                    sx={{
                      textTransform: "none",
                      fontFamily: '"Almarai", sans-serif',
                      color: "#6D6D6D",
                      fontSize: "15px",
                    }}
                    onClick={handleAr}
                  >
                    العربية
                  </Button>
                )}
                {!lan && (
                  <Button
                    sx={{
                      textTransform: "none",
                      fontFamily: '"Quicksand", sans-serif',
                      color: "#6D6D6D",
                      fontSize: "15px",
                    }}
                    onClick={handleEn}
                  >
                    English
                  </Button>
                )}
              </Stack>
            </Stack>

            <Divider color="#B6E6FF" sx={{ height: "1px", mt: "20px" }} />

            <Stack direction="row" sx={{ mt: "20px", pb: "20px" }} gap={2}>
              <Box sx={{ width: "50%" }}>
                {general.facebook && (
                  <Link target="_blank" href={general.facebook}>
                    <FacebookIcon
                      sx={{
                        color: "#64C6C6",
                        cursor: "pointer",
                        fontSize: "25px",
                      }}
                    />
                  </Link>
                )}
                {general.instagram && (
                  <Link target="_blank" href={general.instagram}>
                    <InstagramIcon
                      sx={{
                        color: "#64C6C6",
                        cursor: "pointer",
                        fontSize: "25px",
                      }}
                    />
                  </Link>
                )}
                {general.whatsapp && (
                  <Link
                    target="_blank"
                    href={`https://wa.me/${general.whatsapp}`}
                  >
                    <WhatsAppIcon
                      sx={{
                        color: "#64C6C6",
                        cursor: "pointer",
                        fontSize: "25px",
                      }}
                    />
                  </Link>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "end",
                  justifyContent: "end",
                  width: "50%",
                }}
              >
                <p
                  style={{
                    color: "#B0B0B0",
                    fontSize: "15px",
                    marginTop: "-3px",
                  }}
                >
                  {t("Nairuz")} {new Date().getFullYear()} ©{" "}
                  {t("All Rights Reserved")}
                </p>
              </Box>
            </Stack>
          </Container>
        </footer>
      )}
    </div>
  );
}
