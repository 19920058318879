import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Container, Stack } from "@mui/system";

export default function TechDialog({ open, setOpen, data }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      sx={{
        ".MuiPaper-root": {
          bgcolor: "white",
          border: "4px solid #64c6c2",
          borderRadius: "20px",
        },
      }}
    >
      <DialogContent>
        <Stack direction="row" 
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          p: "20px",
        }}>
          <img src={data.file} style={{ height: "200px", width: "200px" }} />
          <Stack>
            <Container maxWidth='sm'>
              <p style={{ fontWeight: "bold" }}>{data.title}</p>
              <p>{data.text}</p>
            </Container>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
