import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function WhyUsCard({q,i}) {
  return (
    <div>
       <Card sx={{ maxWidth: i === 0 ? 700 : 320 , p:'20px',
        boxShadow:'none', border:'2px solid #64C6C6', borderRadius:'20px',minHeight:'250px'}}>
      <CardContent>
      <CardMedia
        component="img"
        sx={{width:'60px',marginBottom:'20px'}}
        image={q.file}
      />
        <Typography gutterBottom variant="h5" sx={{color:'#64C6C6'}}>
          {q.title}
        </Typography>
        <Typography sx={{color:'#6D6D6D'}}>
          {q.description}
        </Typography>
      </CardContent>
    </Card>
    </div>
  )
}
