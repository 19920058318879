import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    loading: false,
    Authinticate : localStorage.getItem("Authinticate") || false,
    token: localStorage.getItem("token"),
    error: "",
    type: localStorage.getItem("type") || "",
    id: localStorage.getItem("id") || ""
}

export const login = createAsyncThunk("login/loginUser", async (data, { rejectWithValue }) => {
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}login`, data)
      return response.data;
    } catch (error) {
        console.log(error)
      if (error.response && error.response.data && error.response.data.message) {
        return rejectWithValue({ message: error.response.data.message });
      }
      throw error;
    }
  });



    const authReducer = createSlice({
    name: "auth",
    initialState : initialState,
    extraReducers: builder => {
        builder.addCase(login.pending, (state) => {
            state.loading = true
            state.Authinticate = false
            state.error = ''
            state.token = null
            state.type = ""
        })
        builder.addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred while logging in. Please try again.";
        state.Authinticate = false;
        state.token = null;
        state.type = ""
        if(state.status === false){
                alert('error')
        }
        });

        builder.addCase(login.fulfilled, (state, action) => {
            if(action.payload.status){
                state.loading = false
                state.Authinticate = true
                state.token = action.payload.token
                state.error = action.payload.message
                state.type  = action.payload.data.user_type
                localStorage.setItem('Authinticate', true);
                localStorage.setItem('token', action.payload.data.token);
                localStorage.setItem('type', action.payload.data.user.role);
                localStorage.setItem('id', action.payload.data.user.id);
                localStorage.setItem('name', action.payload.data.user.username);
            }
            else{ 
                state.loading = false
                state.error = action.payload.message
                state.isAuth = false
                state.token = null
                state.type = ''
            }
        })

    }
})
export const authAction = authReducer.actions;
export default authReducer.reducer