import { HashRouter, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import Layout from './Layout';
import { useEffect, useState } from 'react';


function App() {
  const lang = localStorage.getItem('lang');

  const theme = createTheme({
    typography: {
      fontFamily: lang === 'en' ? '"League Spartan", sans-serif' : '"Almarai", sans-serif',
    },
  });

  useEffect(() => {
    if (!lang) {
      localStorage.setItem('lang', 'en');
    }
  }, []);

  return (
      <HashRouter>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/*" element={<Layout />} />
          </Routes>
        </ThemeProvider>
      </HashRouter>
  );
}

export default App;
