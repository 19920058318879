import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import logo from "./Media/logo.png";
import { LoginOutlined } from "@mui/icons-material";

function Navbar() {
  const { t, i18n } = useTranslation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [lan, setLan] = React.useState("");
  const pages = [
    t("Home"),
    t("Our Services"),
    t("Why Us?"),
    t("Used Tech"),
    t("FAQs"),
    t("Become a partner"),
  ];
  const lang = localStorage.getItem("lang");
  const [activePage, setActivePage] = React.useState("Home");
  document.body.dir = i18n.dir();

  React.useEffect(() => {
    if (lang === "ar") {
      setLan(false);
    } else {
      setLan(true);
    }
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleEn = () => {
    i18next.changeLanguage("en");
    localStorage.setItem("lang", "en");
    setLan(true);
    window.location.reload(true);
  };

  const handleAr = () => {
    i18next.changeLanguage("ar");
    localStorage.setItem("lang", "ar");
    setLan(false);
    window.location.reload(true);
  };

  const navigate = useNavigate();
  const handleNavigation = (page) => {
    let pageInEnglish;
    switch (page) {
      case t('Home'):
        pageInEnglish = 'Home';
        break;
        case t('Our Services'):
        pageInEnglish = 'Our Services';
        break;
      case t('Why Us?'):
        pageInEnglish = 'Why Us?';
        break;
        case t('Used Tech'):
        pageInEnglish = 'Used Tech';
        break;
        case t('FAQs'):
        pageInEnglish = 'FAQs';
        break;
        case t('Become a partner'):
          pageInEnglish = 'Become a Partner';
          break;
      default:
        pageInEnglish = page;
        break;
    }
    
    if (lang === 'ar'){
      const sectionElement = document.getElementById(pageInEnglish);
      if (sectionElement) {
        const headerHeight = 90;
        const sectionOffset = sectionElement.offsetTop - headerHeight;
        window.scrollTo({ top: sectionOffset, behavior: "smooth" });
      } 
      else {
        navigate("/");
      }
    }
    const mainPagePath = "/";
    const isMainPage = window.location.pathname === mainPagePath;
    setAnchorElNav(null);
    if (!isMainPage) {
      navigate("/");
    } 
    else {
      const sectionElement = document.getElementById(page);
      if (sectionElement) {
        const headerHeight = 90;
        const sectionOffset = sectionElement.offsetTop - headerHeight;
        window.scrollTo({ top: sectionOffset, behavior: "smooth" });
      } 
      else {
        navigate("/");
      }
    }
  };

  const handleGetInTouch =() =>{
    const sectionElement = document.getElementById('support');
    if (sectionElement) {
      const headerHeight = 0;
      const sectionOffset = sectionElement.offsetTop - headerHeight;
      window.scrollTo({ top: sectionOffset, behavior: "smooth" });
      setAnchorElNav(null);
    } 
  }

  return (
    <AppBar position="fixed" sx={{ p: "15px", bgcolor: "white", boxShadow: "none" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography variant="h6" noWrap component="a" href="/">
            <img src={logo} style={{ height: "60px" }} />
          </Typography>

          <Box
            sx={{
              flexGrow: 10,
              display: { xs: "flex", md: "none" },
              justifyContent: "end",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Button
                    textAlign="center"
                    sx={{ color: "black" }}
                    onClick={() => {
                      handleNavigation(page);
                    }}
                  >
                    {page}
                  </Button>
                </MenuItem>
              ))}

              {lan && (
                <Button
                  sx={{
                    my: 2,
                    display: "block",
                    letterSpacing: "0px",
                    color: "black",
                    textTransform: "none",
                    fontSize: "13px",
                    fontFamily: '"Almarai", sans-serif',
                    ml: "10px",
                  }}
                  onClick={handleAr}
                >
                  العربية
                </Button>
              )}
              {!lan && (
                <Button
                  sx={{
                    my: 2,
                    display: "block",
                    color: "black",
                    textTransform: "none",
                    fontSize: "13px",
                    fontFamily: '"League Spartan", sans-serif',
                    mr: "10px",
                  }}
                  onClick={handleEn}
                >
                  English
                </Button>
              )}
              <div
                style={{
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  display:'flex',
                  flexDirection:'column',
                  gap:'20px'
                }}
              >
         <LoginOutlined sx={{ color: "black"}} onClick={()=>{navigate('/login');setAnchorElNav(null)}}/>
                <Button
                  sx={{
                    backgroundImage:
                      "linear-gradient(to right, #64C6C6 , #008080)",
                    color: "white",
                    borderRadius: "50px",
                    width: "150px",
                    height: "30px",
                    textTransform: "none",
                    fontSize: "13px",
                  }}
                  onClick={handleGetInTouch}
                >
                  {t("Get In Touch")}
                </Button>
              </div>
            </Menu>
          </Box>

          <Box
            sx={{ flexGrow: 0.7, display: { xs: "none", md: "flex" } }}
          ></Box>

          <Box sx={{ flexGrow: 0.5 }}>
            <Box sx={{ display: { xs: "none", md: "flex" }, gap: "25px" }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  sx={{
                    my: 2,
                    display: "block",
                    color: page === activePage ? "#64C6C6" : "black",
                    textTransform: "none",
                    fontSize: "13px",
                  }}
                  onClick={() => {
                    handleNavigation(page);
                    setActivePage(page);
                  }}
                >
                  {page}
                </Button>
              ))}

              {lan && (
                <Button
                  sx={{
                    my: 2,
                    display: "block",
                    letterSpacing: "0px",
                    color: "black",
                    textTransform: "none",
                    fontSize: "13px",
                    fontFamily: '"Almarai", sans-serif',
                  }}
                  onClick={handleAr}
                >
                  العربية
                </Button>
              )}
              {!lan && (
                <Button
                  sx={{
                    my: 2,
                    display: "block",
                    color: "black",
                    textTransform: "none",
                    fontSize: "13px",
                    fontFamily: '"League Spartan", sans-serif',
                  }}
                  onClick={handleEn}
                >
                  English
                </Button>
              )}
              <Button
                sx={{
                  backgroundImage:
                    "linear-gradient(to right, #64C6C6 , #008080)",
                  color: "white",
                  borderRadius: "50px",
                  width: "150px",
                  height: "30px",
                  mt: "15px",
                  textTransform: "none",
                  fontSize: "13px",
                  ml: "20px",
                  textAlign:'center',
                  justifyContent:'center',
                  alignItems:'center',
                  display:'flex'
                }}
                onClick={handleGetInTouch}
              >
                {t("Get In Touch")}
              </Button>
              <LoginOutlined
                sx={{ color: "gray", mt: "20px", cursor: "pointer" }}
                onClick={()=>{navigate('/login')}}
              />
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
