import { Container, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import pic from "../Media/roundedVector.png";
import TechsCard from "./TechsCard";

export default function Technologies({techs, technologyDescription}) {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:1024px)");

  return (
    <div style={{ position: "relative" }} id="Used Tech">
      <Stack
        sx={{ display: "flex", alignItems: "center", justifyContent: "center",flexWrap:'wrap' }}
      >
        <Typography sx={{ color: "#64c6c2", fontSize: "30px" ,textAlign:'center'}}>
          {t("Changing Lives in Unprecedented Ways:")}
        </Typography>
        <Typography sx={{ fontSize: "30px",textAlign:'center' }}>
          {t("Tech Like Never Before")}{" "}
        </Typography>
      </Stack>
      <Container maxWidth="sm">
        <p style={{ color: "#B0B0B0", textAlign: "center" }}>
         {technologyDescription}
        </p>
      </Container>

      <Container style={{ position: "relative", marginTop: "50px", }} maxWidth='lg'>
        {matches && (
          <img
            src={pic}
            style={{ position: "absolute", left:localStorage.getItem('lang')==='en'? "-30px":'',
            right:localStorage.getItem('lang')==='ar'? "-20px":''}}
          />
        )}{" "}
        <div
          style={{
            display: matches?"grid":'flex',
            justifyContent:matches ?'':'center',
            alignItems:matches ?'':'center',
            flexWrap:matches?'':'wrap',
            gridTemplateColumns:matches?'repeat(2,1fr)':'repeat(1,1fr)',
            gap: "50px",
            zIndex: 10,
          }}
        >
         {techs.map((t)=> <TechsCard t={t} />)}
          
        </div>
      </Container>
      {matches && (
        <img src={pic} style={{ position:'absolute',marginTop: "20px", marginLeft: "80%" }} />
      )}{" "}
    </div>
  );
}
