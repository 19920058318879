import React, { useState, useRef } from 'react';
import { TextField, MenuItem, InputAdornment, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function TextFieldComp({
  label,
  value,
  onChange,
  width,
  h,
  type,
  data,
  place,
  im,
  dataObj,
  end,
  bg,
  dis,
}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(localStorage.getItem('lang'));
  const [emailError, setEmailError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef(null);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    if (type === 'file') {
      const file = e.target.files[0];
      onChange(file);
    } else {
      const newValue = e.target.value;
      setEmailError(type === 'email' && !validateEmail(newValue));
      onChange(newValue);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const customTheme = (outerTheme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiInputLabel: {
          styleOverrides: {
            root: {
              color: 'gray',
              height: h,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              '& .MuiOutlinedInput-notchedOutline': {
                border: '2px solid #64C6C6',
                height: h,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: '2px solid #64C6C6',
                height: h,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '2px solid #64C6C6',
                height: h,
              },
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              '&::before, &::after': {
                borderBottomColor: '#64C6C6',
                height: h,
              },
              '&:hover:not(.Mui-disabled):before': {
                borderBottomColor: '#64C6C6',
                height: h,
              },
              '&.Mui-focused:after': {
                borderBottomColor: '#64C6C6',
                height: h,
              },
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              '&::before': {
                borderBottomColor: '#64C6C6',
                height: h,
              },
              '&:hover:not(.Mui-disabled):before': {
                borderBottomColor: '#64C6C6',
                height: h,
              },
              '&.Mui-focused:after': {
                borderBottomColor: '#64C6C6',
                height: h,
              },
            },
          },
        },
      },
    });

  const outerTheme = useTheme();

  return (
    <div style={{ marginTop: '10px' }}>
      <label
        style={{
          color: '#3D3D3D',
          fontSize: '15px',
        }}
      >
        {t(label)}
      </label>
      <br />

      <ThemeProvider theme={customTheme(outerTheme)}>
        <TextField
          disabled={dis === true ? true : false}
          SelectProps={{
            value: value,
            multiple: dataObj ? true : false,
          }}
          placeholder={place}
          type={type === 'password' && showPassword ? 'text' : type}
          select={data ? true : dataObj ? true : false}
          onChange={(e) => handleChange(e)}
          error={type === 'email' && emailError}
          helperText={
            type === 'email' && emailError
              ? 'Please enter a valid email address'
              : ''
          }
          sx={{
            backgroundColor: bg ? bg : 'transparent',
            width: width,
            letterSpacing: '0px',
            height: h,
            mt: '5px',
            borderRadius: '10px',
            '& input[type="file"]::-webkit-file-upload-button': {
              display: 'none',
              opacity: 0,
            },
            '& input[type="file"]::file-selector-button': {
              display: 'none',
              opacity: 0,
            },
            '.MuiInputBase-input': {
              height: h,
              color: type === 'file' && !value ? 'transparent' : '',
              mt: type === 'file' && value ? '30px' : '',
            },
            '.MuiInputBase-root ': { height: h, borderRadius: '10px' },
            '.MuiSvgIcon-root': { right: lang === 'en' ? '7px' : '90%' },
            '.MuiSelect-select': {
              display: 'flex',
              flexDirection: 'row',
              overflowX: 'auto',
              gap: '20px',
              justifyContent: 'start',
              alignItems: 'center',
              color: 'black',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {im && (
                  <img src={im} alt="icon" style={{ marginRight: '8px' }}  onClick={() => inputRef.current.click()} />
                )}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {type === 'password' ? (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ) : (
                  end && (
                    <>
                      <img
                        src={end}
                        alt="icon"
                        style={{
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer',
                        }}
                        onClick={() => inputRef.current.click()} 
                      />
                      <input
                        ref={inputRef}
                        type="file"
                        style={{ display: 'none', opacity: 0 }}
                        onChange={(e) => handleChange(e)}
                      />
                    </>
                  )
                )}
              </InputAdornment>
            ),
            ...(type === 'date' && {
              inputProps: {
                placeholder: !value ? 'mm/dd/yyyy' : '',
              },
            }),
          }}
        >
          {place && (
            <MenuItem value={place} disabled>
              <p style={{ color: 'black' }}>{place}</p>
            </MenuItem>
          )}

          {data &&
            data.map((s) => (
              <MenuItem value={s.id}>
                {s.file && (
                  <img
                    src={s.file}
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '100%',
                      margin: '10px',
                    }}
                  />
                )}{' '}
                {s.title}
              </MenuItem>
            ))}
          {dataObj &&
            dataObj.map((s) => (
              <MenuItem value={s.id}>
                {s.image && (
                  <img
                    src={s.image}
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '100%',
                    }}
                  />
                )}
                {s.title}
              </MenuItem>
            ))}
        </TextField>
      </ThemeProvider>
    </div>
  );
}
